import { Input, Form, Button } from 'antd-mobile'
import CurrencyInput from 'react-currency-input-field'
import { React, useState } from 'react'

export default function PurchaseOrderItemEditor ({ activePurchaseOrderItem, onChange }) {
    const { currentInvoiceItem, currentPurchaseOrderItem } = activePurchaseOrderItem

    const [quantity, setQuantity] = useState(currentInvoiceItem?.quantity ?? 0)
    const [rate, setRate] = useState(currentInvoiceItem?.rate ?? 0)

    const subtotal = () => {
        const taxRate = Object.entries(currentInvoiceItem.taxes).filter(([tax, amount]) => tax === 'total')[0][1]
        const amount = (quantity * rate) * (1 + (taxRate / 100.0))
        const locale = currentInvoiceItem.currency === 'INR' ? 'en-IN' : 'en-US'
        return new Intl.NumberFormat(locale, { style: 'currency', currency: currentInvoiceItem.currency }).format(amount)
    }

    if (!currentInvoiceItem) {
        return (
            <></>
        )
    }
    return (
        <Form layout="vertical" footer={
            <Button block type='submit' color='primary' size='large' onClick={() => {
                const params = {
                    currency: currentInvoiceItem.currency,
                    purchase_order_item_id: currentInvoiceItem.purchase_order_item_id,
                    quantity,
                    rate,
                    taxes: currentInvoiceItem.taxes
                }

                onChange(params)
            }}
            style={{ marginBottom: '3rem' }}
            >Save</Button>
        }>
            <Form.Header><h4>Edit Purchase Order Item</h4></Form.Header>
            <Form.Item label="Item">
                {currentPurchaseOrderItem.item_name}
            </Form.Item>
            <Form.Item label="HSN">
                <small>{currentPurchaseOrderItem.item_hsn}</small>
            </Form.Item>
            <Form.Item label="Quantity">
                <Input
                    style={{ '--text-align': 'right' }}
                    defaultValue={currentInvoiceItem?.quantity ?? 0}
                    type="number"
                    onChange={setQuantity}
                    min={0}
                />
            </Form.Item>
            <Form.Item label="Rate">
                <div className='adm-input'>
                    <CurrencyInput
                        id={`rate_${currentPurchaseOrderItem.id}`}
                        name="rate"
                        placeholder="Rate"
                        defaultValue={currentInvoiceItem?.rate ?? 0}
                        decimalsLimit={2}
                        onValueChange={(value, name, values) => {
                            setRate(value)
                        }}
                        intlConfig={{ locale: 'en-IN', currency: currentInvoiceItem.currency || 'INR' }}
                        style={{ '--text-align': 'right' }}
                        className='adm-input-element'
                    />
                </div>
            </Form.Item>
            <Form.Item label="Tax">
                <div className="text-right" style={{ color: 'var(--adm-color-weak)' }}>
                    {Object.entries(currentInvoiceItem.taxes).map(([tax, amount]) => tax === 'total' ? <div key={tax}>Total: {amount}</div> : <div key={tax}>{tax}: {amount}</div>)}
                </div>
            </Form.Item>
            <Form.Item label="Subtotal">
                <div className="text-right" style={{ color: 'var(--adm-color-weak)' }}>
                    {subtotal(currentInvoiceItem)}
                </div>
            </Form.Item>
        </Form>
    )
}
