import { List, Tag } from 'antd-mobile'
import React from 'react'

export default function ApprovalLog ({ invoice, showAllApprovalLog }) {
    const formatDate = (date) => {
        if (!(date instanceof Date && !isNaN(date))) { return date }
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const day = date.getDate()
        const monthIndex = date.getMonth()
        const year = date.getFullYear()
        let hours = date.getHours()
        const minutes = date.getMinutes()
        const ampm = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12 || 12 // Convert midnight to 12
        const minutesFormatted = minutes < 10 ? '0' + minutes : minutes

        return `${day} ${months[monthIndex]}, ${year} ${hours}:${minutesFormatted} ${ampm}`
    }

    const renderLog = (log) => {
        const who = log.UsersEmail || log.email || null
        let when = log.CreatedAt || log.when || null

        const type = log.type ? log.type + ': ' : ''
        const func = log.fn || ''
        const what = log.Description || log.what || <Tag color='default'>{type}{func}</Tag>

        if (typeof when === 'string') {
            when = new Date(when)
        }

        return (
            <List.Item key={log.Id}>
                {who && <Tag color='primary' style={{ float: 'right' }}>{who}</Tag>}

                <div style={{ whiteSpace: 'pre-wrap', color: 'var(--adm-color-text)' }} dangerouslySetInnerHTML={{ __html: what }}></div>

                <div>
                    <small>
                        {formatDate(when)}
                    </small>
                </div>
            </List.Item>
        )
    }

    const genfilter = () => {
        if (showAllApprovalLog) {
            return () => true
        }

        return (log) => {
            const type = log.type ? log.type + ': ' : ''
            const func = log.fn || ''
            const what = log.Description || log.what || type + func

            return !(what.indexOf('Sent mail') > -1 || what.indexOf('Clicked on') > -1 || what.indexOf('Opened') > -1 || what.indexOf('Modified') > -1)
        }
    }

    return (
        <div style={{ marginLeft: '-12px' }}>
            {Object.values(invoice.Log).filter(genfilter()).map((log, index) => renderLog(log))}
        </div>
    )
}
