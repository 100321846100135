import { NavBar } from 'antd-mobile'
import { React } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Payments (props) {
    const navigate = useNavigate()
    return (
        <div>
            <NavBar onBack={() => {
                navigate(-1)
            }}>
                Payments
            </NavBar>

            <h1>Payments</h1>
            <p>Coming soon...</p>
        </div>
    )
}
