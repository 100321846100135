import { List, Tag } from 'antd-mobile'
import { React } from 'react'
import { SpicUtils } from '../../../utils'

export default function CustomPayments (props) {
    const formatCurrency = (amount) => props.currencyFormatter.format(amount)

    return (
        <List style={{ '--font-size': 'var(--adm-font-size-4)', '--padding-left': '0', '--padding-right': '0' }}>
            {props.customPayments.length && props.customPayments?.map(
                (cp, index) => <List.Item
                    key={cp.Id}
                    prefix={<Tag color="default">{cp.Idx}</Tag>}
                    extra={<div style={{ textAlign: 'right' }}>
                        <div style={{ fontSize: '90%', color: 'var(--adm-color-text)' }}>{formatCurrency(cp.PayableAmount)}&nbsp;<Tag color='default'>P</Tag></div>
                        <div style={{ fontSize: '80%' }}>{formatCurrency(cp.ApprovedAmount)}&nbsp;<Tag color='default'>A</Tag></div>
                        <div style={{ fontSize: '70%' }}>{formatCurrency(cp.OriginalAmount)}&nbsp;<Tag color='default'>O</Tag></div>
                        <div>{cp.Status}</div>
                    </div>}
                    description={cp.Name}
                >
                    <div><a target='_blank' href={SpicUtils.linkTo(cp.GrapevineDocId)} rel="noreferrer"><Tag color='primary'>{cp.GrapevineDocId}</Tag></a></div>
                    <div><b>Account:</b> {cp.Account}</div>
                    <div><b>IFSC:</b> {cp.IFSC}</div>

                </List.Item>
            )}
        </List>
    )
}
