import { useLoaderData, useNavigate } from 'react-router-dom'
import { List, NavBar, Grid, Input, Switch, Steps, Collapse, Popup, Form, Button, Card, NoticeBar, CalendarPicker, Picker, Space } from 'antd-mobile'
import { SpicUtils } from '../../utils'
import { React, useEffect, useState } from 'react'
import ApprovalLog from './invoice_detail/ApprovalLog'
import ApprovalCard from './invoice_detail/ApprovalCard'
import PurchaseOrderDetails from './invoice_detail/PurchaseOrderDetails'
import GrapevineDocDetails from './invoice_detail/GrapevineDocDetails'
import CustomPayments from './invoice_detail/CustomPayments'
import Attachments from './invoice_detail/Attachments'
import TransactionReference from './invoice_detail/TransactionReference'

export default function InvoiceDetail (props) {
    const invoice = useLoaderData()

    const [showAllApprovalLog, setShowAllApprovalLog] = useState(false)

    const [workflowSteps, setWorkflowSteps] = useState([])

    const [customPayments, setCustomPayments] = useState([])

    const [purchaseOrderItems, setPurchaseOrderItems] = useState([])

    const [currentUserId, setCurrentUserId] = useState(-1)

    const navigate = useNavigate()

    useEffect(() => {
        SpicUtils.getInvoiceWorkflowstepsForInvoice(invoice.Id).then((data) => {
            setWorkflowSteps(data)
            const currentStep = data?.length && data.filter((step) => step.Id === invoice.CurrentStep)[0]
            currentStep && setRequestedAmount(currentStep.RequestedAmount)
        })

        SpicUtils.getCustomPayments(invoice.Id).then((data) => {
            data.length ? setCustomPayments(data) : setCustomPayments(invoice.Meta.custom_payments)
        })

        SpicUtils.whoami().then(userId => {
            setCurrentUserId(userId)
        })
    }, [invoice])

    const currentStepIndex = () => {
        if (!workflowSteps) return null
        const currentStep = invoice.CurrentStep
        const step = workflowSteps.filter((step) => step.Id === currentStep)[0]
        return (step && (step.Step - 1)) || null
    }

    const currentWorkflowstep = () => workflowSteps?.length && currentStepIndex() && workflowSteps[currentStepIndex()]

    const isApprovableByMe = () => (((currentWorkflowstep()?.UserId?.Id || invoice?.CurrentUserId) === currentUserId) && invoice.Status === 2)

    const currencyFormatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: invoice.Currency

        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    const [requestedAmount, setRequestedAmount] = useState(0)

    // console.log(invoice)
    return (
        <div>
            <div className="top-nav">
                <NavBar onBack={() => {
                    navigate(-1)
                }}>
                    <span style={{ color: '#8b0000', fontFamily: 'monospace' }}>{invoice.Id}</span><span style={{ paddingRight: '0.5rem' }}>:</span><span>{invoice.Number}</span>
                </NavBar>
            </div>

            <Card
                title={<h4>Invoice Details</h4>}
                extra={<b>{invoice.Id}</b>}
                bodyClassName="invoice-detail-card-body"
            >
                <Grid columns={4} gap={['0.5rem', '1.2rem']}>
                    <Grid.Item span={2}>
                        <div><b>No</b></div>
                        <div>{invoice.Number}</div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div className="text-right"><b>Date</b></div>
                        <div className="text-right">{(new Date(invoice.Date)).toLocaleDateString()}</div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div><b>Vendor</b></div>
                        <div dangerouslySetInnerHTML={{ __html: invoice.VendorId.text || '' }}></div>
                        <div style={{ display: 'none', whiteSpace: 'pre-wrap' }}>{invoice.VendorId.Address}</div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div><b>GSTIN</b><div>{invoice.Meta.vendor_gstin || ''}</div></div>
                    </Grid.Item>
                    {invoice.VendorId.CIN && <Grid.Item><div><b>CIN</b><div>{invoice.VendorId.CIN || ''}</div></div></Grid.Item>}
                    <Grid.Item span={4}>
                        <div><b>Buyer</b></div>
                        <div>{invoice.CompanyId.Name}</div>
                        <div style={{ display: 'none', whiteSpace: 'pre-wrap' }}>{invoice.CompanyId.Address}</div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div><b>GSTIN</b><div>{invoice.Meta.company_gstin || ''}</div></div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div><b>Description</b></div>
                        <div dangerouslySetInnerHTML={{ __html: invoice.Description || '' }}></div>
                    </Grid.Item>
                    <Grid.Item span={4}>
                        <div className="text-right" style={{ borderBottom: '1px solid #ccc', paddingBottom: '0.5rem', color: '#000' }}>
                            <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: invoice.StatusDisplay || '' }}></div>
                        </div>
                    </Grid.Item>
                </Grid>
            </Card>

            {invoice.GrapevinePurchaseOrderId && <PurchaseOrderDetails
                invoice={invoice}
                updateChanges={
                    (requestedAmount, purchaseOrderItems) => {
                        setRequestedAmount(requestedAmount)
                        setPurchaseOrderItems(purchaseOrderItems)
                    }
                }
                isApprovableByMe={isApprovableByMe()}
            />}

            {invoice.GrapevineDocId && <GrapevineDocDetails
                invoice={invoice}
                isApprovableByMe={isApprovableByMe()}
                currencyFormatter={currencyFormatter}
            />}

            {currentStepIndex() !== null && <ApprovalCard
                invoice={invoice}
                requestedAmount={requestedAmount}
                currencyFormatter={currencyFormatter}
                currentWorkflowstep={currentWorkflowstep()}
                purchaseOrderItems={purchaseOrderItems}
                isApprovableByMe={isApprovableByMe()}
            />}

            <Card>
                <div style={{ margin: '0 -12px' }}>
                    <Collapse>

                        <Collapse.Panel key="approvalLog" title={<>
                            <span className="adm-collapse-title">
                                <h4 style={{ paddingRight: '1rem' }}>Approval Log</h4>
                                <span className="text-muted">Show&nbsp;</span>
                                <span onClick={(e) => e.stopPropagation()}>
                                    <Switch
                                        uncheckedText={<span className='text-muted'>All</span>}
                                        checkedText={<span className='text-muted' style={{ color: 'var(--adm-color-white)' }}>Filtered</span>}
                                        checked={!showAllApprovalLog}
                                        onChange={(x) => {
                                            setShowAllApprovalLog(!x)
                                        }}
                                    />
                                </span>
                            </span>
                        </>}>
                            <ApprovalLog invoice={invoice} showAllApprovalLog={showAllApprovalLog}/>
                        </Collapse.Panel>

                        <Collapse.Panel key="workflow" title={
                            <span className="adm-collapse-title">
                                <h4>Workflow</h4>
                            </span>
                        }>
                            <Steps
                                direction="vertical"
                                current={currentStepIndex()}
                                style={{
                                    '--indicator-margin-right': '0.5rem'
                                }}
                            >
                                {workflowSteps?.map((item, index) => {
                                    return <Steps.Step key={item.Id} title={item.UserId.Email} icon={<span className='wfstep'>{item.Step}</span>}/>
                                })}
                                {workflowSteps?.length === 0 && <div>No workflow steps</div>}
                            </Steps>
                        </Collapse.Panel>

                        <Collapse.Panel key="attachments" title={
                            <span className="adm-collapse-title">
                                <h4>Attachments</h4>
                            </span>
                        }>
                            <Attachments
                                currencyFormatter={currencyFormatter}
                                invoice={invoice}
                                isApprovableByMe={isApprovableByMe}
                            />

                        </Collapse.Panel>

                        {customPayments && customPayments?.length > 0 &&
                        <Collapse.Panel key="custom-payments" title={
                            <span className="adm-collapse-title">
                                <h4>Payment Guidance</h4>
                            </span>
                        }>
                            <CustomPayments
                                customPayments={customPayments}
                                currencyFormatter={currencyFormatter}
                                invoice={invoice}
                            />

                        </Collapse.Panel>
                        }

                        {invoice.Meta && invoice.Meta.transaction_reference &&
                        <Collapse.Panel key="transaction-reference" title={
                            <span className="adm-collapse-title">
                                <h4>Payment Reference</h4>
                            </span>
                        }>
                            <TransactionReference
                                paymentError={invoice.Meta?.payment_error}
                                transactionReferences={invoice.Meta.transaction_reference?.amount ? [invoice.Meta.transaction_reference] : invoice.Meta.transaction_reference}
                                currencyFormatter={currencyFormatter}
                                invoice={invoice}
                            />

                        </Collapse.Panel>
                        }

                    </Collapse>
                </div>
            </Card>

            <div style={{ height: '4rem' }}>&nbsp;</div>

        </div>
    )
}
