import { Grid, Card, NoticeBar, DatePicker, TextArea, Button, Toast } from 'antd-mobile'
import { React, useEffect, useState } from 'react'
import { SpicUtils } from '../../../utils'
import { useNavigate } from 'react-router-dom'
import CurrencyInput from 'react-currency-input-field'
import Deductions from './Deductions'

export default function ApprovalCard ({ invoice, requestedAmount, currentWorkflowstep, purchaseOrderItems, isApprovableByMe, currencyFormatter }) {
    const navigate = useNavigate()

    const [dnpb, setDNPB] = useState(new Date(invoice?.DoNotPayBefore).toISOString().split('T')[0])
    const [dnpbVisible, setDNPBVisible] = useState(false)

    const formatCurrency = (amount) => currencyFormatter.format(amount)

    const formatDNPB = (dateString) => dateString ? ' after ' + new Date(dateString).toLocaleDateString() : 'Immediately'

    const [approvedAmount, setApprovedAmount] = useState(parseFloat(currentWorkflowstep?.RequestedAmount || 0))

    const [payableAmount, setPayableAmount] = useState(invoice?.PayableAmount)

    useEffect(() => {
        setApprovedAmount(parseFloat(currentWorkflowstep?.RequestedAmount || 0))
    }, [currentWorkflowstep])

    const recalculateAdjustments = () => {
        return invoiceAdjustments.map((d) => d.amount).reduce((a, b) => a + b, 0)
    }

    const recalulatePayableAmount = () => {
        setPayableAmount(approvedAmount - recalculateAdjustments())
    }

    useEffect(() => {
        approvedAmount && recalulatePayableAmount()
    }, [approvedAmount])

    const [invoiceAdjustments, setInvoiceAdjustments] = useState(invoice?.DeductionDetails)

    const [comments, setComments] = useState()

    const now = new Date()

    const rejectMe = () => {
        if (!(comments?.length > 0)) {
            Toast.show({ content: 'Please provide comments for rejection' })
            return
        }

        if (payableAmount < 0) {
            Toast.show({ content: 'Payable amount cannot be negative' })
            return
        }

        SpicUtils.rejectInvoiceWorkflowstep(
            currentWorkflowstep.Id,
            approvedAmount,
            recalculateAdjustments(),
            comments,
            dnpb,
            invoiceAdjustments,
            null,
            null,
            purchaseOrderItems
        ).then((data) => {
            console.log(data)
            if (data.status === 'error') {
                Toast.show({ content: data.message })
            } else if (data.status === 'ok') {
                Toast.show({ content: 'Invoice rejected successfully' })
                navigate('/invoices')
            }
        })
    }

    const approveMe = () => {
        if (payableAmount < 0) {
            Toast.show({ content: 'Payable amount cannot be negative' })
            return
        }

        SpicUtils.approveInvoiceWorkflowstep(
            currentWorkflowstep.Id,
            approvedAmount,
            recalculateAdjustments(),
            comments,
            dnpb,
            invoiceAdjustments,
            null,
            null,
            purchaseOrderItems
        ).then((data) => {
            console.log(data)
            if (data.status === 'error') {
                Toast.show({ content: data.message })
            } else if (data.status === 'ok') {
                Toast.show({ content: 'Invoice approved successfully' })
                navigate('/invoices')
            }
        })
    }

    return (
        <Card
            title={<h4>Approval</h4>}
            bodyClassName="invoice-detail-card-body"
        >
            <Grid columns={4} gap={['0.5rem', '1.2rem']}>
                <Grid.Item span={2}>
                    <div><b>Requested Amount: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <br/>
                    <div className="text-right">{requestedAmount && formatCurrency(requestedAmount)}</div>
                </Grid.Item>

                {requestedAmount !== currentWorkflowstep?.RequestedAmount && <Grid.Item span={4}>
                    <NoticeBar color='alert' content={`Please re-confirm the approved amount. The original approved amount was ${formatCurrency(currentWorkflowstep?.RequestedAmount)}`} />
                </Grid.Item>}
                <Grid.Item span={2}>
                    <br/>
                    <div><b>Approved Amount: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <br/>
                    {isApprovableByMe
                        ? <CurrencyInput
                            id={`approvedAmount_${currentWorkflowstep.Id}`}
                            name="approvedAmount"
                            value={approvedAmount}
                            decimalsLimit={2}
                            onValueChange={(value, name, values) => {
                                console.log(value, name, values)
                                setApprovedAmount(value)
                            }}
                            intlConfig={{ locale: 'en-IN', currency: invoice.Currency || 'INR' }}
                            style={{ '--text-align': 'right', fontWeight: 'bold', color: 'var(--color-text-primary)' }}
                            className='adm-input-element'
                        />
                        : <div className="text-right">{formatCurrency(approvedAmount)}</div>}
                </Grid.Item>

                <Deductions
                    invoiceAdjustments={invoiceAdjustments}
                    companyId={invoice.CompanyId.Id}
                    currency={invoice.Currency}
                    formatCurrency={formatCurrency}
                    updateInvoiceAdjustments={setInvoiceAdjustments}
                    isApprovableByMe={isApprovableByMe}
                />

                <Grid.Item span={2}>
                    <br/>
                    <div><b>Payable Amount: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <br/>
                    <div className="text-right">{formatCurrency(payableAmount)}</div>
                </Grid.Item>

                {isApprovableByMe && <Grid.Item span={4}>
                    <br/>
                    <div><b>Comments: </b></div>
                    <TextArea
                        placeholder='Comments'
                        value={comments}
                        onChange={val => {
                            setComments(val)
                        }}
                    />
                </Grid.Item>}

                <Grid.Item span={2}>
                    <br/>
                    <div><b>Pay after</b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <DatePicker
                        title='Do Not Pay Before'
                        cancelText='Cancel'
                        confirmText='Ok'
                        visible={dnpbVisible}
                        onClose={() => {
                            setDNPBVisible(false)
                        }}
                        min={now}
                        max={new Date(new Date().setFullYear(now.getFullYear() + 1))}
                        onConfirm={val => {
                            setDNPB(val)
                        }}
                    />
                    <br/>
                    <div onClick={() => {
                        if (isApprovableByMe) {
                            setDNPBVisible(true)
                        }
                    }} className="text-right">{formatDNPB(dnpb)}</div>

                </Grid.Item>

                {isApprovableByMe && <>
                    <Grid.Item span={4} style={{ borderBottom: '0.5px solid var(--adm-border-color)', paddingTop: '1rem' }}>
                        {invoice?.Warnings.length
                            ? <NoticeBar
                                closeable={true}
                                wrap={true}
                                content={invoice.Warnings.reduce(
                                    (acc, w) => {
                                        return acc + w + '. '
                                    }, ''
                                )}
                            >

                            </NoticeBar>
                            : <div>&nbsp;</div>}
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <Button color='danger' block={true} onClick={rejectMe}>Reject</Button>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <Button color='success' block={true} onClick={approveMe}>Approve</Button>
                    </Grid.Item>
                </>}

            </Grid>
            <br/>
        </Card>
    )
}
