import { Outlet } from 'react-router-dom'
import Bottom from '../Bottom'
import { React } from 'react'

export default function Home (props) {
    return (
        <div className="Home">
            <div className="HomeTop">

            </div>
            <div className='HomeBody'>
                <Outlet></Outlet>
            </div>
            <div className='HomeBottom'>
                <Bottom></Bottom>
            </div>
        </div>
    )
}
