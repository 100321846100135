import { List } from 'antd-mobile'
import { FileIcon, defaultStyles } from 'react-file-icon'
import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import { SpicUtils } from '../../../utils'

export default function Attachments (props) {
    const [attachments, setAttachments] = useState([])

    const formatDate = (date) => {
        const d = new Date(date)
        const options = { day: '2-digit', month: 'short', year: 'numeric' }
        return d.toLocaleString('en-IN', options)
    }

    const listAttachments = () => {
        const _class = SpicUtils.getInvoiceClass(props.invoice)
        SpicUtils.listAttachments(props.invoice.Id, _class).then((data) => {
            data.length && setAttachments(data)
        })
    }

    useEffect(() => {
        listAttachments()
    }, [props.invoice.Id])

    return (
        <>
            <List style={{ '--font-size': 'var(--adm-font-size-7)', '--padding-left': '0', '--padding-right': '0' }}>
                {attachments.length && attachments?.map(
                    (cp, index) => <List.Item
                        key={`attachment_${cp.name}`}
                        prefix={
                            <a target="_blank" href={cp.url} style={{ color: 'var(--adm-color-weak)', textDecoration: 'none' }} rel="noreferrer">
                                <div style={{ width: '48px', margin: '0.5rem' }}>
                                    <FileIcon extension={cp.ext} {...defaultStyles[cp.ext]} />
                                </div>
                            </a>}
                        extra={<div style={{ textAlign: 'right' }}>
                            <small>{formatDate(cp.created)}</small>

                        </div>}
                    >
                        <a target="_blank" href={cp.url} style={{
                            color: 'var(--adm-color-weak)',
                            textDecoration: 'none',
                            maxWidth: '40vw',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block'
                        }} rel="noreferrer">
                            <div style={{
                                color: 'var(--adm-color-text)'
                            }}>{cp.name}</div>
                            <div>{cp.size}</div>
                            <small>{cp?.meta?.Metadata?.uploaded_by}</small>
                        </a>
                    </List.Item>
                )}
            </List>
            {props.isApprovableByMe() && <Dropzone onDrop={acceptedFiles => {
                console.log(acceptedFiles)
                acceptedFiles.forEach(file => {
                    SpicUtils.uploadAttachment(props.invoice.Id, file).then((data) => {
                        console.log(data)
                        listAttachments()
                    })
                })
            }}>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Click to select files to upload</p>
                        </div>
                    </section>
                )}
            </Dropzone>}
        </>
    )
}
