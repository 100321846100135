import './App.css'
import { React } from 'react'
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider
} from 'react-router-dom'

import Home from './components/Home'
import Invoices from './components/Invoices'
import InvoiceDetail from './components/invoice/InvoiceDetail'
import Payments from './components/Payments'
import Archive from './components/Archive'
import Dashboard from './components/Dashboard'
import { SpicUtils } from './utils'

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Home></Home>}>
            <Route index element={<Dashboard />} />
            <Route path="pending" element={<Invoices pendingOnly={true}/>} />
            <Route path="invoices" element={<Invoices pendingOnly={false}/>} />
            <Route path="invoices/:invoiceId" element={<InvoiceDetail/>} loader={async ({ params }) => SpicUtils.getInvoice(params.invoiceId)}/>
            <Route path="payments" element={<Payments/>} />
            <Route path="archive" element={<Archive/>} />
            <Route path="archive/query/:query" element={<Archive/>} />
            <Route path="archive/invoice/:invoiceId" element={<InvoiceDetail/>} loader={async ({ params }) => SpicUtils.getInvoiceFromArchive(params.invoiceId)}/>
        </Route>
    ), {
        basename: process.env.PUBLIC_URL
    }
)

function App () {
    return (
        <RouterProvider router={router} />
    )
}

export default App
