import { React, Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Grid, Tag, Button, List, Space, NoticeBar, Badge } from 'antd-mobile'
import { SpicUtils } from '../../../utils'
import Messages from './Messages'
import { FlexibleWidthXYPlot, VerticalBarSeries, HorizontalGridLines, XAxis, YAxis, LabelSeries } from 'react-vis'
import parsePhoneNumber from 'libphonenumber-js'
import { CircleCheck, CircleX } from 'lucide-react'

export default function GrapevineDocDetails ({ invoice, isApprovableByMe, currencyFormatter }) {
    const [doc, setDoc] = useState(null)
    const [messages, setMessages] = useState([])
    const [checklist, setChecklist] = useState([])

    useEffect(() => {
        const _class = SpicUtils.getInvoiceClass(invoice)
        SpicUtils.getInvoiceExtendedInformation(invoice.id, _class).then((data) => {
            setDoc(data.result.doc)
            setMessages(data.result.messages)
            setChecklist(data.result.checklist)
        })
    }, [invoice])

    // console.log(doc, messages, checklist)
    return <>
        {doc && <Card
            title={
                <>
                    <h4 style={ { paddingRight: '0.3rem' } }>
                        {doc._name}:
                    </h4>
                    <a
                        target='_blank'
                        rel="noreferrer"
                        onClick={(e) => {
                            window.open(SpicUtils.linkTo(`${doc._class}=${doc.id}`), '_blank')
                            e.stopPropagation()
                        }
                        }
                    >{doc.name}</a>
                </>
            }
            bodyClassName="invoice-detail-card-body"
        >
            {doc.asset && <AssetAnalysis asset={doc.asset} currencyFormatter={currencyFormatter} />}
            {doc.customer && <CustomerAnalysis customer={doc.customer} currencyFormatter={currencyFormatter} />}
            {doc.employee && <EmployeeAnalysis doc={doc} currencyFormatter={currencyFormatter} />}
            {doc.order_shipment && <OrderShipmentAnalysis doc={doc} currencyFormatter={currencyFormatter} />}
            {doc.payment_contract_invoice && <PaymentContractInvoiceAnalysis doc={doc} currencyFormatter={currencyFormatter} invoice={invoice} />}
            {doc.transport_record && <TransportRecordAnalysis doc={doc} currencyFormatter={currencyFormatter} invoice={invoice} />}
            {doc.invoices && <InvoicesAnalysis doc={doc} currencyFormatter={currencyFormatter} />}
            {doc.expenses && <ExpenseAnalysis expenses={doc.expenses} currencyFormatter={currencyFormatter} />}
        </Card>}
        {messages && <Messages messages={messages} />}
    </>
}

function ExpenseAnalysis ({ expenses }) {
    // Helper function to extract year from the label
    const extractYear = (label) => {
        const match = label.match(/\((\d{4})\)/)
        return match ? parseInt(match[1], 10) : null
    }

    const exp = expenses.map((expense, index) => ({
        x: expense[0].replace('MTD Total', 'MTD').replace('Total', 'Tot'), // Abbreviate labels
        y: expense[1],
        year: extractYear(expense[0])
    })).sort((a, b) => a.year - b.year)

    const formatNumber = (num) => {
        if (num >= 10000000) {
            return (num / 10000000).toFixed(1) + ' Cr' // Crores
        } else if (num >= 100000) {
            return (num / 100000).toFixed(1) + ' L' // Lakhs
        } else {
            return num
        }
    }

    const calculateChanges = (data) => {
        const changes = []
        for (let i = 1; i < data.length; i++) {
            const percentageChange = ((data[i].y - data[i - 1].y) / data[i - 1].y) * 100
            const arrow = percentageChange > 0 ? '▲' : '▼'
            const color = percentageChange > 0 ? 'red' : 'green'

            let label = `${arrow} ${Math.abs(percentageChange).toFixed(1)}%`
            // Check if percentageChange is Infinity
            if (!isFinite(percentageChange)) {
                label = `${arrow} ∞`
            }

            changes.push({
                x: data[i].x,
                y: data[i].y + (data[i].y * 0.05), // Position the label slightly above the bar
                label: label,
                style: { fontSize: 12, fill: color, textAnchor: 'middle' }
            })
        }
        return changes
    }

    const mtdData = exp.filter(item => item.x.includes('MTD'))
    const totData = exp.filter(item => item.x.includes('Tot'))

    const mtdChanges = calculateChanges(mtdData)
    const totChanges = calculateChanges(totData)

    return (
        <div style={{ width: '100%' }}>
            <h4 style={ { margin: '0 0 1rem 0' } }>Expense Analysis</h4>
            <FlexibleWidthXYPlot
                className="expense-plot"
                xType="ordinal"
                height={220}
            >
                <HorizontalGridLines style={{ stroke: '#CCC' }} />
                <XAxis
                    tickLabelAngle={-25}
                    style={{
                        text: { fontSize: '8px' } // Adjust font size for readability
                    }}
                />
                <YAxis
                    tickFormat={formatNumber} // Use custom format function
                    style={{
                        text: { fontSize: '8px' } // Adjust font size for readability
                    }}
                />
                <VerticalBarSeries
                    cluster="MTD"
                    color="#3366cc"
                    data={mtdData}
                />
                <VerticalBarSeries
                    cluster="Tot"
                    color="#6699ff"
                    data={totData}
                />
                <LabelSeries
                    data={mtdChanges}
                    labelAnchorX="middle"
                    labelAnchorY="text-after-edge"
                />
                <LabelSeries
                    data={totChanges}
                    labelAnchorX="middle"
                    labelAnchorY="text-after-edge"
                />
            </FlexibleWidthXYPlot>
        </div>
    )
}

function AssetAnalysis ({ asset, currencyFormatter }) {
    return (
        <div>
            <h4 style={ { margin: '1rem 0' } }>Asset Analysis</h4>
            <Grid columns={4} gap={['0.5rem', '1.2rem']}>
                <Grid.Item span={2}>
                    <div><b> Book Value: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <br/>
                    <div className="text-right">{currencyFormatter.format(asset.bookValue)}</div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Operating Costs: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <br/>
                    <div className="text-right">{currencyFormatter.format(asset.operatingCost)}</div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Total Costs: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <br/>
                    <div className="text-right">{currencyFormatter.format(asset.totalCost)}</div>
                </Grid.Item>
                {
                    asset.imageUrl &&
                        <Grid.Item span={4}>
                            <img src={asset.imageUrl} title={asset.fileName} style="max-width:100%;"/>
                        </Grid.Item>
                }
            </Grid>
        </div>
    )
}

function CustomerAnalysis ({ customer, currencyFormatter }) {
    return (
        <div>
            <h4 style={ { margin: '1rem 0' } }>Customer Analysis</h4>
            <Grid columns={4} gap={['0.5rem', '1.2rem']}>
                <Grid.Item span={2}>
                    <div><b>Account Rep: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <br/>
                    <div className="text-right">{customer.account_rep_name}</div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Branch: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <br/>
                    <div className="text-right">{
                        customer.branch_location_names.map((branch, index) => (
                            <Fragment key={index}>
                                <Tag fill='solid'>{branch}</Tag>
                            </Fragment>
                        ))
                    }</div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Bank Accounts: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <br/>
                    <div className="text-right">{
                        customer.bank_account_nos.map((accountNo, index) => (
                            <Fragment key={index}>
                                <Tag fill='outline'>{accountNo}</Tag>
                            </Fragment>
                        ))
                    }</div>
                </Grid.Item>
                <Grid.Item span={4}>
                    <Space direction='vertical' justify='center' style={ { width: '100%' } }>
                        <Button
                            size='middle'
                            color='primary'
                            fill='outline'
                            style={ { width: '100%' } }
                            onClick={(e) => {
                                window.open(SpicUtils.linkTo(`Order&f=filterByCustomerId,${customer.id}`), '_blank')
                                e.stopPropagation()
                            }}
                        >View Orders</Button>

                        <Button
                            size='middle'
                            color='primary'
                            fill='outline'
                            style={ { width: '100%' } }
                            onClick={(e) => {
                                window.open(SpicUtils.linkTo(`OrderShipment&f=filterByCustomerId,${customer.id}`), '_blank')
                                e.stopPropagation()
                            }}
                        >View Shipments</Button>

                        <Button
                            size='middle'
                            color='primary'
                            fill='outline'
                            style={ { width: '100%' } }
                            onClick={(e) => {
                                window.open(SpicUtils.linkTo(`SalesReturn&f=filterByCustomerId,${customer.id}`), '_blank')
                                e.stopPropagation()
                            }}
                        >View Returns</Button>

                        <Button
                            size='middle'
                            color='primary'
                            fill='outline'
                            style={ { width: '100%' } }
                            onClick={(e) => {
                                window.open(`//grapevine.hartex.in/ui/finance/collections.php?CustomerId=${customer.id}`, '_blank')
                                e.stopPropagation()
                            }}
                        >View Collections</Button>

                        <Button
                            size='middle'
                            color='primary'
                            fill='outline'
                            style={ { width: '100%' } }
                            onClick={(e) => {
                                window.open(SpicUtils.linkTo(`CustomerAdjustment&f=filterByCustomerId,${customer.id}`), '_blank')
                                e.stopPropagation()
                            }}
                        >View Adjustments</Button>
                    </Space>
                </Grid.Item>
            </Grid>
        </div>
    )
}

function InvoicesAnalysis ({ doc, currencyFormatter }) {
    const navigate = useNavigate()

    const excessPaymentsNoticeBar = () => <>
        <NoticeBar content={`This ${doc._class} has excess payments in addition to the original quote. Do not approve payment without checking carefully!`} color='error' />
    </>

    const renderInvoice = (invoice) => {
        const link = invoice.source === 'archive' ? `/archive/invoices/${invoice.id}` : `/invoices/${invoice.id}`

        return (
            <List.Item
                key={invoice.id}
                description={invoice.name}
                extra={<Tag>{invoice.status_text}</Tag>}
                onClick={() => navigate(link)}
            >
                <span className='invoiceId'>{invoice.id}</span> - {currencyFormatter.format(invoice.original_amount)}
            </List.Item>
        )
    }

    const currency = currencyFormatter.resolvedOptions().currency

    return (
        <div>
            <h4 style={ { margin: '1rem 0' } }>Other Invoices</h4>
            {doc?.invoices?.invoices?.length > 1 && excessPaymentsNoticeBar()}
            <List
                header={`${doc?.invoices?.summary[currency]?.total_count} Invoices, Total Value: ${currencyFormatter.format(doc?.invoices?.summary[currency]?.total_original_amount)}`}
                style={{ '--font-size': 'var(--adm-font-size-4)', '--padding-left': '0', '--padding-right': '0' }}>
                {doc.invoices.invoices.map((invoice, index) => renderInvoice(invoice))}
            </List>
        </div>
    )
}

function EmployeeAnalysis ({ doc, currencyFormatter }) {
    // parse the employee.start_date as YYYY-MM-DD and render as d-M-Y
    const formatDate = (date) => {
        console.log(date)
        return new Date(date).toLocaleDateString()
    }

    const formatBankAccountStatus = (status) => {
        switch (status) {
        case 100:
            return <Tag color='green'>Active</Tag>
        case -100:
            return <Tag color='red'>Inactive</Tag>
        default:
            return status
        }
    }

    const employee = doc.employee

    return (
        <div style={ { margin: '0 0 1rem 0' } }>
            <Grid columns={4} gap={['0.5rem', '1.2rem']}>
                <Grid.Item span={2}>
                    <div><b>Name: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">{employee.name}</div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Start Date: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">{formatDate(doc.start_date)}</div>
                </Grid.Item>
                {doc.exit_date &&
                    <>
                        <Grid.Item span={2}>
                            <div><b>Exit Date: </b></div>
                        </Grid.Item>
                        <Grid.Item span={2}>
                            <div className="text-right">{formatDate(doc.exit_date)}</div>
                        </Grid.Item>
                    </>
                }
                {!doc.exit_date && doc.probation_end_date &&
                    <>
                        <Grid.Item span={2}>
                            <div><b>Probation End Date: </b></div>
                        </Grid.Item>
                        <Grid.Item span={2}>
                            <div className="text-right">{formatDate(doc.probation_end_date)}</div>
                        </Grid.Item>
                    </>
                }
                <Grid.Item span={2}>
                    <div><b>Designation: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">{employee.designation_name}</div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Department: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">{employee.department_name}</div>
                </Grid.Item>
                <Grid.Item span={1}>
                    <div><b>Locations: </b></div>
                </Grid.Item>
                <Grid.Item span={3}>
                    <div className="text-right">
                        {employee.employee_location_names.split(',').map((o, i) => <Tag key={`loc_${i}`}>{o}</Tag>)}
                    </div>
                </Grid.Item>
                <Grid.Item span={4}>
                    <div><b>Bank Accounts: </b></div>
                </Grid.Item>
                <Grid.Item span={4}>
                    <Grid columns={4} gap={['0.5rem', '1.2rem']}>
                        {employee.bank_account_details.map((account, index) => (
                            <Fragment key={index}>
                                <Grid.Item span={3}>
                                    {account.beneficiary_name}
                                    <br />
                                    <Tag fill='outline'>{account.number}: {account.code}</Tag>
                                </Grid.Item>
                                <Grid.Item span={1} className='text-right'>
                                    {formatBankAccountStatus(account.status)}
                                </Grid.Item>
                            </Fragment>
                        ))}
                    </Grid>
                </Grid.Item>
            </Grid>
        </div>
    )
}

function OrderShipmentAnalysis ({ doc, currencyFormatter }) {
    const phoneNumber = parsePhoneNumber(doc.customer_location.telephone, doc.customer_location.country)
    const numberFormat = new Intl.NumberFormat('en-IN')

    const renderDepartureDate = () => {
        const now = Date.now()
        if (doc.departure_date) {
            const departureDate = new Date(doc.departure_date)
            if (departureDate > now) {
                return <Tag color='warning'>{departureDate.toLocaleDateString()} (Expected)</Tag>
            } else {
                return <Tag color='success'>{departureDate.toLocaleDateString()}</Tag>
            }
        } else {
            return <Tag color='default'>Unknown</Tag>
        }
    }

    const renderDeliveryDate = () => {
        const now = Date.now()
        if (doc.delivery_date) {
            const deliveryDate = new Date(doc.delivery_date)
            if (deliveryDate > now) {
                return <Tag color='warning'>{deliveryDate.toLocaleDateString()} (Expected)</Tag>
            } else {
                return <Tag color='success'>{deliveryDate.toLocaleDateString()}</Tag>
            }
        } else {
            return <Tag color='default'>Unknown</Tag>
        }
    }

    const renderEstimatedAdHocFreightCharge = () => {
        const adHocFreightCharge = doc.meta?.AdHocFreightCharge || 0
        const adHocFreightPercentage = (adHocFreightCharge / doc.meta.TaxableAmount) * 100
        return `${currencyFormatter.format(adHocFreightCharge)} (${adHocFreightPercentage.toFixed(2)}%)`
    }

    const renderActualDuration = () => {
        // Calculate the difference between the delivery date and the departure date
        const departureDate = new Date(doc.departure_date)
        const deliveryDate = new Date(doc.delivery_date)
        const duration = deliveryDate - departureDate
        const days = Math.floor(duration / (1000 * 60 * 60 * 24))
        return `${days} days`
    }

    const renderCostTonKm = () => {
        const tons = doc.order_shipment.weight / 1000
        const km = doc.distance.distance.value / 1000
        const cost = doc.invoices.summary[currencyFormatter.resolvedOptions().currency].total_original_amount
        const renderCostTonKm = currencyFormatter.format(cost / (tons * km))
        return renderCostTonKm
    }

    const renderCostPack = () => {
        const cost = doc.invoices.summary[currencyFormatter.resolvedOptions().currency].total_original_amount
        const renderCostPack = currencyFormatter.format(cost / parseInt(doc.order_shipment.bundles, 10))
        return renderCostPack
    }

    const renderCostPc = () => {
        const cost = doc.invoices.summary[currencyFormatter.resolvedOptions().currency].total_original_amount
        const renderCostPc = currencyFormatter.format(cost / parseInt(doc.order_shipment.pcs, 10))
        return renderCostPc
    }

    return (
        <div>
            <h4 style={ { margin: '1rem 0' } }>Order Shipment Analysis</h4>
            <Grid columns={4} gap={['0.5rem', '1.2rem']}>
                <Grid.Item span={2}>
                    <div><b>Customer Location: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        <div>{doc.customer_location.name}</div>
                        <div>{doc.customer_location.address}</div>
                        <div>{doc.customer_location.city} {doc.customer_location.pincode}</div>
                        <div>{doc.customer_location.state} {doc.customer_location.country}</div>
                        <div>Tel: {phoneNumber.formatInternational()}</div>
                    </div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Status: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        <Tag style={ { backgroundColor: doc.status_color_map[doc.status][0], color: doc.status_color_map[doc.status][1] } }>{doc.status_text_map[doc.status]}</Tag>
                    </div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Departure Date: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        {renderDepartureDate()}
                    </div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Delivery Date: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        {renderDeliveryDate()}
                    </div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Order Shipment Amount: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        {currencyFormatter.format(doc.meta.TaxableAmount)}
                    </div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Estimated Ad-Hoc Freight Charge: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        {renderEstimatedAdHocFreightCharge()}
                    </div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Weight: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        {numberFormat.format(parseFloat(doc.order_shipment.weight).toFixed(0))} kg
                    </div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Packs: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        {numberFormat.format(parseFloat(doc.order_shipment.bundles).toFixed(0))} bdls
                    </div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Pcs: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        {numberFormat.format(parseFloat(doc.order_shipment.pcs).toFixed(0))} pcs
                    </div>
                </Grid.Item>
                {doc.mode === 0 && <>
                    <Grid.Item span={2}>
                        <div><b>Distance: </b></div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div className="text-right">
                            {doc.distance.distance.text}
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div><b>Expected Duration: </b></div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div className="text-right">
                            {doc.distance.duration.text}
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div><b>Actual Duration: </b></div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div className="text-right">
                            {renderActualDuration()}
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div><b>Cost/ton/km: </b></div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div className="text-right">
                            {renderCostTonKm()}
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div><b>Cost/pack: </b></div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div className="text-right">
                            {renderCostPack()}
                        </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div><b>Cost/pc: </b></div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div className="text-right">
                            {renderCostPc()}
                        </div>
                    </Grid.Item>
                </>}
            </Grid>
        </div>
    )
}

function PaymentContractInvoiceAnalysis ({ doc, currencyFormatter, invoice }) {
    console.log(invoice)
    const renderExpectedVendor = () => {
        const icon = invoice.VendorId.Id === doc.payment_contract_invoice.spic_vendor_id ? <CircleCheck style={ { verticalAlign: 'middle' } }/> : <CircleX style={ { verticalAlign: 'middle' } }/>
        const color = invoice.VendorId.Id === doc.payment_contract_invoice.spic_vendor_id ? 'success' : 'danger'
        return <Tag color={color} round={true}>{icon} &nbsp; {doc.payment_contract_invoice.vendor_name}</Tag>
    }

    return (
        <div>
            <Grid columns={4} gap={['0.5rem', '1.2rem']}>
                <Grid.Item span={2}>
                    <div><b>Rate: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">{currencyFormatter.format(parseFloat(doc.payment_contract_invoice.rate))}</div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Initiated On: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">{(new Date(doc.payment_contract_invoice.occurrence)).toLocaleDateString()}</div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Expires On: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">{(new Date(doc.payment_contract_invoice.expires_on)).toLocaleDateString()}</div>
                </Grid.Item>
                <Grid.Item span={4}>
                    <div><b>Expected Vendor: </b></div>
                    <div className="text-right">{renderExpectedVendor()}</div>
                </Grid.Item>
            </Grid>
        </div>
    )
}

function TransportRecordAnalysis ({ doc, currencyFormatter, invoice }) {
    const currency = currencyFormatter.resolvedOptions().currency
    console.log(doc)

    const renderArrivalDate = () => {
        const expected = doc?.expected_arrival_date && new Date(doc.expected_arrival_date)
        const actual = doc?.actual_arrival_date && new Date(doc.actual_arrival_date)

        if (actual) {
            if (actual > expected) {
                return <Tag color='warning'>{actual.toLocaleDateString()} (Delayed)</Tag>
            } else {
                return <Tag color='success'>{actual.toLocaleDateString()}</Tag>
            }
        } else {
            if (expected) {
                if (expected > Date.now()) {
                    return <Tag color='warning'>{expected.toLocaleDateString()} (Expected)</Tag>
                } else {
                    return <Tag color='danger'>{expected.toLocaleDateString()} (Expected)</Tag>
                }
            } else {
                return <Tag color='danger'>Unknown</Tag>
            }
        }
    }

    const renderCostPerTonPerKm = () => {
        const cost = doc.invoices.summary[currency].total_original_amount
        const km = Math.max(doc.meta.Distance.distance.value / 1000.0, 0.01)
        const tons = Math.max(doc.meta.TotalWeight / 1000.0, 0.0001)
        const costPerTonPerKm = cost / (tons * km)
        return currencyFormatter.format(costPerTonPerKm)
    }

    const renderPurchaseOrderItemTransportationCost = (item) => {
        const extendedText = parseInt(item.transport_required) === -1 ? 'Not Required' : 'Additional'
        const extendedTextColor = parseInt(item.transport_required) === -1 ? 'danger' : 'success'

        let conversionFactor = null
        if (item.ConversionFactor) {
            const unit = item.uom_unit || item.uom_name
            conversionFactor = <div>Conversion Factor {item.ConversionFactor}kg/{unit}</div>
        } else if (item.uom_name.indexOf('gram') === -1) {
            conversionFactor = <div style={ { color: 'var(--adm-color-danger)' } }>Conversion Factor: Undefined!</div>
        }

        return (
            <List.Item
                description={conversionFactor}
                extra={<Tag color={extendedTextColor}>{extendedText}</Tag>}
            >
                <span style={ { fontSize: 'var(--adm-font-size-7)' } }>{item.item_name}</span>
            </List.Item>
        )
    }

    return (
        <div>
            <Grid columns={4} gap={['0.5rem', '1.2rem']}>
                <Grid.Item span={2}>
                    <div><b>Purchase Order Value: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">{currencyFormatter.format(parseFloat(doc.purchase_order.net_value[currency]))}</div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div><b>Status: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        <Tag style={ { backgroundColor: doc.status_color_map[doc.status][0], color: doc.status_color_map[doc.status][1] } }>{doc.status_text_map[doc.status]}</Tag>
                    </div>
                </Grid.Item>
                {
                    doc.mode === 1 &&
                    <>
                        <Grid.Item span={2}>
                            <div><b>Bill of Entry: </b></div>
                        </Grid.Item>
                        <Grid.Item span={2}>
                            <div className="text-right">
                                {doc.transport_record.messages.BillOfEntryNo}
                                <Tag style={ { backgroundColor: doc.status_color_map[doc.status][0], color: doc.status_color_map[doc.status][1] } }>{doc.status_text_map[doc.status]}</Tag>
                                (`${doc.transport_record.messages.message_count}` messages)
                            </div>
                        </Grid.Item>
                    </>
                }

                <Grid.Item span={2}>
                    <div><b>Arrival Date: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        {renderArrivalDate()}
                    </div>
                </Grid.Item>

                {doc.meta.Distance.distance && <>
                    <Grid.Item span={2}>
                        <div><b>Distance: </b></div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div className="text-right">
                            {doc.meta.Distance.distance.text}
                        </div>
                    </Grid.Item>

                    <Grid.Item span={2}>
                        <div><b>Standard Duration: </b></div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <div className="text-right">
                            {doc.meta.Distance.duration.text}
                        </div>
                    </Grid.Item>
                </>
                }

                <Grid.Item span={2}>
                    <div><b>Expected Duration: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        <Tag color={doc.transport_record.duration_metrics.ed_color}>{doc.transport_record.duration_metrics.expected_duration} days</Tag>
                    </div>
                </Grid.Item>

                <Grid.Item span={3}>
                    <div><b>Actual Duration: </b></div>
                    <div><i>Vendor Avg Actual Duration: </i></div>
                    <div><i>Transporter Avg Actual Duration: </i></div>
                </Grid.Item>
                <Grid.Item span={1}>
                    <div className="text-right">
                        <Tag color={doc.transport_record.duration_metrics.ad_color}>{doc.transport_record.duration_metrics.actual_duration} days</Tag>
                        <div><i>{parseInt(doc.transport_record.vendor_metrics.avg_actual_duration, 10)} days</i></div>
                        <div><i>{parseInt(doc.transport_record.transporter_metrics.avg_actual_duration, 10)} days</i></div>
                    </div>
                </Grid.Item>

                <Grid.Item span={2}>
                    <div><b>Delay: </b></div>
                    <div><i>Vendor Avg Delay: </i></div>
                    <div><i>Transporter Avg Delay: </i></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        <Tag color={doc.transport_record.duration_metrics.dl_color}>{doc.transport_record.duration_metrics.delay} days</Tag>
                        <div><i>{parseInt(doc.transport_record.vendor_metrics.avg_delay, 10)} days</i></div>
                        <div><i>{parseInt(doc.transport_record.transporter_metrics.avg_delay, 10)} days</i></div>
                    </div>
                </Grid.Item>

                <Grid.Item span={2}>
                    <div><b>Average Vendor Delay: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        <Tag color={doc.transport_record.duration_metrics.ad_color}>{parseInt(doc.transport_record.vendor_metrics.avg_actual_duration, 10)} days</Tag>
                    </div>
                </Grid.Item>

                <Grid.Item span={2}>
                    <div><b>Weight: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        {doc.meta.TotalWeight} kg
                    </div>
                </Grid.Item>

                <Grid.Item span={2}>
                    <div><b>Cost/ton/km: </b></div>
                </Grid.Item>
                <Grid.Item span={2}>
                    <div className="text-right">
                        {doc.meta.Distance.distance ? renderCostPerTonPerKm() : 'N/A'}
                    </div>
                </Grid.Item>

                <Grid.Item span={4}>
                    <List header={<b>Transportation Cost</b>}>
                        {doc.purchase_order.purchase_order_items.map((item, index) => renderPurchaseOrderItemTransportationCost(item))}
                    </List>
                </Grid.Item>
            </Grid>
        </div>
    )
}
