import { List, InfiniteScroll, NavBar, DotLoading, SearchBar, SwipeAction, Switch, Popup, Form, Button, DatePicker, Input, Toast, Selector, Picker, Space, Empty } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import { React, useEffect, useState, useRef } from 'react'
import { SpicUtils } from '../utils'
import CurrencyInput from 'react-currency-input-field'
import { InvoiceItem } from './InvoiceItem'

export default function Invoices (props) {
    const [data, setData] = useState([])
    const maxPerPage = 50
    const page = useRef(1)
    const [hasMore, setHasMore] = useState(true)

    const [markPaidItem, setMarkPaidItem] = useState(false)
    const navigate = useNavigate()

    const [query, setQuery] = useState('')
    const [results, setResults] = useState([])

    const [currentUserId, setCurrentUserId] = useState(-1)
    const resultPage = useRef(1)

    async function loadMore () {
        const newData = await SpicUtils.getInvoices(page.current, maxPerPage)
        const pc = data.length + newData.items.length
        setData([...data, ...newData.items])
        setHasMore(pc < newData.total_count)
        page.current = page.current + 1
    }

    const search = async () => {
        const v = encodeURIComponent(query)
        const newData = await SpicUtils.getInvoices(resultPage.current, maxPerPage, query)
        const pc = results.length + newData.items.length

        if (resultPage.current === 1) {
            setResults(newData.items)
        } else {
            setResults([...results, ...newData.items])
        }

        resultPage.current = resultPage.current + 1
        setHasMore(pc < newData.total_count)
    }

    const reloadInvoices = () => console.log('reload invoices')

    useEffect(() => {
        SpicUtils.whoami().then(userId => {
            setCurrentUserId(userId)
        })
    }, [])

    // New Search
    useEffect(() => {
        if (!isSearchMode()) return
        setResults([])
        resultPage.current = 1
        search()
    }, [query])

    const isSearchMode = () => (query && query.length >= 3)

    let k = isSearchMode() ? results : data
    k = props.pendingOnly ? k.filter((i) => i.CurrentUserId === currentUserId && i.Status === 2) : k
    k.sort((a, b) => new Date(a.DoNotPayBefore) - new Date(b.DoNotPayBefore))

    return (
        <div>
            <div className='top-nav'>
                <NavContent setQuery={setQuery} pendingOnly={props.pendingOnly}/>
            </div>

            {k.length
                ? <>
                    <List>
                        {k.map((item, index) => {
                            return (
                                <SwipeableInvoiceItem key={item.Id} item={item} reloadInvoices={reloadInvoices} launchMarkPaidModal={setMarkPaidItem} onClick={() => navigate(`/invoices/${item.Id}`)}/>
                            )
                        })}
                    </List>
                </>
                : <Empty />}
            <InfiniteScroll loadMore={isSearchMode() ? search : loadMore} hasMore={hasMore}>
                <>
                    {hasMore ? <div style={{ textAlign: 'center' }}>Loading<DotLoading /></div> : <div style={{ textAlign: 'center' }}>No more data</div>}
                </>
            </InfiniteScroll>

            <MarkPaidModal item={markPaidItem} setItem={setMarkPaidItem}/>
        </div>
    )
}

function NavContent (props) {
    const navigate = useNavigate()
    const [isSearchActive, setIsSearchActive] = useState(false)

    return (
        <>
            <div style={{ maxHeight: (isSearchActive) ? '0rem' : '3rem' }}>
                <NavBar
                    onBack={() => {
                        navigate(-1)
                    }}
                    right={!isSearchActive && <>
                        <Switch
                            uncheckedText='All'
                            checked = {props.pendingOnly}
                            checkedText='Pending'
                            style={{ '--checked-color': 'var(--adm-color-weak)' }}
                            onChange={
                                (v) => v ? navigate('/pending') : navigate('/invoices')
                            }/>
                    </>}
                >
                    <b>Invoices</b>
                </NavBar>
            </div>

            <div style={{ padding: '0.3rem' }}>
                <SearchBar
                    placeholder='Search'
                    cancelText='Cancel'
                    onlyShowClearWhenFocus={true}
                    showCancelButton={true}
                    onFocus={() => setIsSearchActive(true)}
                    onBlur={() => setIsSearchActive(false)}
                    onSearch={(value) => props.setQuery(value)}
                    onClear={() => props.setQuery('')}
                />
            </div>
        </>
    )
}

function SwipeableInvoiceItem ({ item, reloadInvoices, launchMarkPaidModal, onClick }) {
    const leftActions = (item) => {
        const leftActions = [
            {
                key: 'delete-invoice',
                text: 'Delete',
                color: 'danger',
                onClick: async (e) => SpicUtils.deleteInvoice(item.Id).then((response) => {
                    Toast.show({
                        icon: response.status === 'ok' ? 'success' : 'fail',
                        duration: 4000,
                        content: response.message.length ? response.message : (response.status === 'ok' ? 'Invoice deleted successfully.' : 'Failed to delete invoice.')
                    })
                    response.status === 'ok' && reloadInvoices()
                })
            },
            {
                key: 'rescind-invoice',
                text: 'Recall',
                color: 'danger',
                onClick: async (e) => SpicUtils.recallInvoice(item.Id).then((response) => {
                    Toast.show({
                        icon: response.status === 'ok' ? 'success' : 'fail',
                        duration: 4000,
                        content: response.message.length ? response.message : (response.status === 'ok' ? 'Invoice recalled successfully.' : 'Failed to recall invoice.')
                    })
                    response.status === 'ok' && reloadInvoices()
                })
            },
            {
                key: 'hold-longer',
                text: 'Hold',
                color: 'warning',
                onClick: async (e) => SpicUtils.holdInvoice(item.Id).then((response) => {
                    if (response.status === 'ok') {
                        Toast.show({
                            icon: response.status === 'ok' ? 'success' : 'fail',
                            duration: 4000,
                            content: response.message.length ? response.message : `Invoice held until ${(new Date(response.result.date)).toDateString()}`
                        })
                        reloadInvoices()
                    } else {
                        Toast.show({
                            icon: response.status === 'ok' ? 'success' : 'fail',
                            duration: 4000,
                            content: response.message
                        })
                    }
                })
            }
        ]

        // return only the actions that are available for the item - item is an array of strings corresponding to keys in leftActions
        return leftActions.filter((o) => item.Actions.includes(o.key))
    }

    const rightActions = (item) => {
        const rightActions = [
            {
                key: 'submit-invoice',
                text: 'Submit',
                color: 'primary',
                onClick: async (e) => SpicUtils.submitInvoice(item.Id).then((response) => {
                    Toast.show({
                        icon: response.status === 'ok' ? 'success' : 'fail',
                        duration: 4000,
                        content: response.message.length ? response.message : (response.status === 'ok' ? 'Invoice submitted successfully.' : 'Failed to submit invoice.')
                    })
                    response.status === 'ok' && reloadInvoices()
                })
            },
            {
                key: 'pay-now',
                text: 'Pay',
                color: 'success',
                onClick: async (e) => SpicUtils.autopayInvoice(item.Id).then((response) => {
                    Toast.show({
                        icon: response.status === 'ok' ? 'success' : 'fail',
                        duration: 4000,
                        content: response.message.length ? response.message : (response.status === 'ok' ? 'Invoice sent for payment.' : 'Failed to send invoice for payment.')
                    })
                    response.status === 'ok' && reloadInvoices()
                })
            },
            {
                key: 'retry-autopay',
                text: 'Retry',
                color: 'warning',
                onClick: async (e) => SpicUtils.autopayInvoice(item.Id).then((response) => {
                    Toast.show({
                        icon: response.status === 'ok' ? 'success' : 'fail',
                        duration: 4000,
                        content: response.message.length ? response.message : (response.status === 'ok' ? 'Invoice sent for auto-payment.' : 'Failed to auto-pay invoice.')
                    })
                    response.status === 'ok' && reloadInvoices()
                })
            },
            {
                key: 'mark-paid',
                text: 'Mark Paid',
                color: 'light',
                onClick: (e) => {
                    launchMarkPaidModal(item)
                }
            }
        ]

        // return only the actions that are available for the item - item is an array of strings corresponding to keys in leftActions
        return rightActions.filter((o) => item.Actions.includes(o.key))
    }

    const description = (item) => <div>
        <span dangerouslySetInnerHTML={{ __html: item.StatusDisplay || '' }}></span>
    </div>

    const buildBorder = (leftActions, rightActions) => {
        const colorMap = { success: 'var(--adm-color-success)', danger: 'var(--adm-color-danger)', warning: 'var(--adm-color-warning)', primary: 'var(--adm-color-primary)', light: 'var(--adm-color-light)' }
        const width = '5px'
        const style = {}

        if (leftActions.length > 0) {
            let leftGradient = []
            let leftPrevious = 0
            for (let i = 0; i < leftActions.length; i++) {
                leftGradient.push(`${colorMap[leftActions[i].color]} ${leftPrevious}% ${leftPrevious + 100 / leftActions.length}%`)
                leftPrevious = leftPrevious + 100 / leftActions.length
            }
            leftGradient = leftGradient.join(', ')

            style['--left-border'] = `${width} solid`
            style['--left-border-image'] = `linear-gradient(to bottom, ${leftGradient}) 5`
        }

        if (rightActions.length > 0) {
            let rightGradient = []
            let rightPrevious = 0
            for (let i = 0; i < rightActions.length; i++) {
                rightGradient.push(`${colorMap[rightActions[i].color]} ${rightPrevious}% ${rightPrevious + 100 / rightActions.length}%`)
                rightPrevious = rightPrevious + 100 / rightActions.length
            }
            rightGradient = rightGradient.join(', ')

            style['--right-border'] = `${width} solid`
            style['--right-border-image'] = `linear-gradient(to bottom, ${rightGradient}) 5`
        }

        return style
    }

    return (
        <SwipeAction
            key={`sw_${item.id}`}
            leftActions={leftActions(item)}
            rightActions={rightActions(item)}
        >
            <List.Item key={item.id} description={description(item)} className='invoiceItem' style={buildBorder(leftActions(item), rightActions(item))} onClick={onClick}>
                <InvoiceItem item={item} />
            </List.Item>
        </SwipeAction>
    )
}

function MarkPaidModal (props) {
    const [date, setDate] = useState(new Date())
    const [amount, setAmount] = useState(props.item.PayableAmount || props.item.OriginalAmount)

    const [datePickerVisible, setDatePickerVisible] = useState(false)

    const [type, setType] = useState('tt')
    const [referenceNo, setReferenceNo] = useState('')

    const [bankAccountPickerVisible, setBankAccountPickerVisible] = useState(false)
    const [bankAccounts, setBankAccounts] = useState([])
    const [bankAccountId, setBankAccountId] = useState([])

    const getBankAccountsForInvoice = async () => {
        return await SpicUtils.getBankAccountsForInvoice(props.item.Id).then((response) => {
            return response.data.map((ba) => { return { value: ba.id, label: ba.text } })
        })
    }

    useEffect(() => {
        if (props.item) {
            getBankAccountsForInvoice().then((response) => {
                // console.log([response])
                setBankAccounts([response])
            })
        }
    }, [props.item])

    return (
        <Popup
            visible={!!props.item}
            closeOnMaskClick={true}
            showCloseButton={true}
            onMaskClick={() => {
                props.setItem(false)
            }}
            onClose={() => {
                props.setItem(false)
            }}
        >
            <Form layout="vertical" footer={
                <Button block type='submit' color='primary' size='large' onClick={() => {
                    const params = {
                        date,
                        amount,
                        type,
                        referenceNo,
                        bankAccountId: bankAccountId && bankAccountId[0]
                    }

                    props.setItem(false)

                    SpicUtils.markPaidInvoice(props.item.Id, params)
                        .then((response) => {
                            Toast.show({
                                icon: response.status === 'ok' ? 'success' : 'fail',
                                duration: 4000,
                                content: response.message.length ? response.message : (response.status === 'ok' ? 'Invoice marked as paid.' : 'Failed to mark invoice as paid.')
                            })
                            // response.status == 'ok' && reloadInvoices()
                        }
                        )
                }}
                style={{ marginBottom: '3rem' }}
                >Mark Paid</Button>
            }>
                <Form.Header>Mark Paid</Form.Header>

                <Form.Item
                    label='Date'
                    rules={[{ required: true, message: 'When was the payment made?' }]}
                    trigger='onConfirm'
                    onClick={(e, datePickerRef) => {
                        setDatePickerVisible(true)
                        datePickerRef.current?.open() // ⬅️
                    }}
                >

                    <DatePicker
                        visible={datePickerVisible}
                        title='Date'
                        cancelText='Cancel'
                        confirmText='Ok'
                        max={new Date()}
                        defaultValue={new Date()}
                        onCancel={() => {
                            setDatePickerVisible(false)
                        }}
                        onConfirm={val => {
                            setDatePickerVisible(false)
                            setDate(val)
                        }}
                        value={date}
                    >
                        {value => value && value.toDateString()}
                    </DatePicker>
                </Form.Item>

                <Form.Item
                    label='Amount'
                    rules={[{ required: true, message: 'Please enter the payment amount' }]}
                >
                    <div className='adm-input'>
                        <CurrencyInput
                            id="input-example"
                            name="amount"
                            placeholder="Payment Amount"
                            defaultValue={amount}
                            decimalsLimit={2}
                            onValueChange={(value, name, values) => {
                                setAmount(value)
                            }}
                            intlConfig={{ locale: 'en-IN', currency: props.item.Currency || 'INR' }}
                            className='adm-input-element'
                        />
                    </div>
                </Form.Item>

                <Form.Item
                    label='Type'
                    rules={[{ required: true, message: 'Please enter the payment type' }]}
                >
                    <Selector
                        options={[
                            { value: 'cheque', label: 'Cheque' },
                            { value: 'rtgs', label: 'RTGS' },
                            { value: 'neft', label: 'NEFT' },
                            { value: 'upi', label: 'UPI' },
                            { value: 'tt', label: 'TT' },
                            { value: 'spic', label: 'Other SPIC Invoice' }
                        ]}
                        defaultValue={[type]}
                        onChange={(arr, extend) => setType(arr[0])}
                    />
                </Form.Item>

                <Form.Item
                    label='Reference Number'
                    rules={[{ required: true, message: 'Please enter the bank reference no' }]}
                >
                    <Input
                        value={referenceNo}
                        onChange={(v) => setReferenceNo(v.toLocaleUpperCase().replace(/[^0-9A-Z]/gi, ''))}
                    />
                </Form.Item>

                <Form.Item
                    label='Bank Account'
                    rules={[{ required: true, message: 'Please select the bank account' }]}
                    trigger='onConfirm'
                    onClick={(e, baPickerRef) => {
                        setBankAccountPickerVisible(true)
                        baPickerRef.current?.open() // ⬅️
                    }}
                >

                    <Picker
                        title='Select Bank Account'
                        cancelText='Cancel'
                        confirmText='Ok'
                        columns={bankAccounts}
                        visible={bankAccountPickerVisible}
                        value={bankAccountId}
                        onCancel={() => setBankAccountPickerVisible(false)}
                        onClose={() => setBankAccountPickerVisible(false)}
                        onConfirm={(v) => setBankAccountId(v)}
                        onSelect={(val, extend) => setBankAccountId(val)}
                    >
                        {(items, { open }) => {
                            return (
                                <Space align='center'>
                                    {items.every(item => item === null)
                                        ? 'None'
                                        : items.map(item => item?.label ?? 'Unknown').join(' - ')}
                                </Space>
                            )
                        }}
                    </Picker>
                </Form.Item>
            </Form>
        </Popup>
    )
}
