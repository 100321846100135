import { NavBar, SearchBar, Switch, Form, Button, Input, Popup, DatePicker, List, Empty, InfiniteScroll, DotLoading } from 'antd-mobile'
import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { React, useState, useEffect, useRef } from 'react'
import { SpicUtils } from '../utils'
import { ListFilter } from 'lucide-react'
import AsyncSelect from 'react-select/async'
import { InvoiceItem } from './InvoiceItem'

export default function Archive (props) {
    const maxPerPage = 50

    const [hasMore, setHasMore] = useState(false)

    const params = useParams()
    const [advancedSearchOptions, setAdvancedSearchOptions] = useState({})

    const [results, setResults] = useState([])

    const resultPage = useRef(1)

    const navigate = useNavigate()

    const search = async () => {
        let hasAdvancedSearchKeys = false
        // Check if advancedSearchOptions is an object
        if (typeof advancedSearchOptions === 'object') {
            // remove empty properties from advancedSearchOptions
            Object.keys(advancedSearchOptions).forEach(key => advancedSearchOptions[key] === '' && delete advancedSearchOptions[key])
            hasAdvancedSearchKeys = !!(Object.keys(advancedSearchOptions).length)
        }

        // Check if advancedSearchOptions has any keys
        console.log(hasAdvancedSearchKeys, advancedSearchOptions)
        if ((params?.query?.length < 3) && !hasAdvancedSearchKeys) return

        const v = encodeURIComponent(params.query)

        const newData = await SpicUtils.searchArchive(resultPage.current, maxPerPage, params.query, advancedSearchOptions)

        const pc = results.length + newData.items.length

        if (resultPage.current === 1) {
            setResults(newData.items)
        } else {
            setResults([...results, ...newData.items])
        }

        resultPage.current = resultPage.current + 1
        setHasMore(pc < newData.total_count)
    }

    const newAdvancedSearch = async (options) => {
        setResults([])
        resultPage.current = 1
        setAdvancedSearchOptions(options)
        search()
    }

    useEffect(() => {
        newAdvancedSearch()
    }, [params.query])

    const queryChange = (value) => {
        if (value === params.query) return
        if (value.length < 3) return
        navigate(`/archive/query/${value}`)
    }

    const description = (item) => <div>
        <span dangerouslySetInnerHTML={{ __html: item.StatusDisplay || '' }}></span>
    </div>

    const l = results.length ? results : []
    l.sort((a, b) => new Date(a.UpdatedAt) - new Date(b.UpdatedAt))

    return (
        <div>
            <div className='top-nav'>
                <NavContent handleQueryChange={queryChange} handleSearch={newAdvancedSearch}/>
            </div>
            <div name='content'>
                {l.length
                    ? <>
                        <List>
                            {l.map((item, index) => {
                                return (
                                    <List.Item key={item.id} description={description(item)} className='invoiceItem' onClick={() => navigate(`/archive/invoice/${item.Id}`)}>
                                        <InvoiceItem item={item} />
                                    </List.Item>
                                )
                            })}
                        </List>
                    </>
                    : <Empty />}
                <InfiniteScroll loadMore={search} hasMore={hasMore}>
                    <>
                        {hasMore ? <div style={{ textAlign: 'center' }}>Loading<DotLoading /></div> : <div style={{ textAlign: 'center' }}>No more data</div>}
                    </>
                </InfiniteScroll>
            </div>
        </div>
    )
}

function NavContent (props) {
    const navigate = useNavigate()
    const [isSearchActive, setIsSearchActive] = useState(false)
    const [isAdvancedSearchActive, setIsAdvancedSearchActive] = useState(false)

    return (
        <>
            <div style={{ maxHeight: (isSearchActive) ? '0rem' : '3rem' }}>
                <NavBar
                    onBack={() => {
                        navigate(-1)
                    }}

                >
                    <b>Archive</b>
                </NavBar>
            </div>

            <div style={{
                padding: '0.3rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>

                <SearchBar
                    placeholder='Search'
                    cancelText='Cancel'
                    onlyShowClearWhenFocus={true}
                    showCancelButton={true}
                    onFocus={() => setIsSearchActive(true)}
                    onBlur={() => setIsSearchActive(false)}
                    onSearch={(value) => props.handleQueryChange(value)}
                    onClear={() => props.handleQueryChange('')}
                    style={{ width: '80%', margin: '0.5rem' }}
                />

                <button
                    onClick={() => {
                        setIsAdvancedSearchActive(!isAdvancedSearchActive)
                    }}
                    style={
                        {
                            backgroundColor: isAdvancedSearchActive ? 'var(--adm-color-primary)' : 'transparent',
                            border: '1px solid black',
                            borderRadius: '0.5rem',
                            borderColor: !isAdvancedSearchActive ? 'var(--adm-color-primary)' : 'transparent',
                            padding: '0.5rem',
                            margin: '0.5rem'
                        }
                    }
                >
                    <ListFilter
                        color={isAdvancedSearchActive ? '#fff' : '#333'}
                        size={16}
                    />
                </button>
            </div>

            <Popup
                visible={isAdvancedSearchActive}
                onMaskClick={() => setIsAdvancedSearchActive(false)}
                onClose={() => setIsAdvancedSearchActive(false)}
                direction='bottom'
                maskClosable={true}
                bodyStyle={{ height: '67vh' }}
            >
                <AdvancedSearch handleSearch={props.handleSearch}/>
            </Popup>
        </>
    )
}

function AdvancedSearch (props) {
    const [id, setId] = useState('')
    const [invoiceNumber, setInvoiceNumber] = useState('')

    const [startDatePickerVisible, setStartDatePickerVisible] = useState(false)
    const [endDatePickerVisible, setEndDatePickerVisible] = useState(false)
    const [startDate, setStartDate] = useState(false)
    const [endDate, setEndDate] = useState(false)

    const [vendor, setVendor] = useState('')
    const [company, setCompany] = useState('')

    const getVendorOptions = async (q) => {
        const endpoint = '/actions/crud.json.php'
        return await SpicUtils.fetch(`${endpoint}?_class=Vendor&depth=0&q=${q}`)
            .then(response => response.json())
            .then((data) => {
                if (data.status === 'ok') {
                    return data.items
                }
            }).then((items) => {
                return items.map((item) => {
                    return { value: item.Id, label: item.Name }
                })
            })
    }

    const getCompanyOptions = (q) => {
        const endpoint = '/actions/crud.json.php'
        return SpicUtils.fetch(`${endpoint}?_class=Company&depth=0&q=${q}`)
            .then(response => response.json())
            .then((data) => {
                if (data.status === 'ok') {
                    return data.items
                }
            }).then((items) => {
                return items.map((item) => {
                    return { value: item.Id, label: item.Name }
                })
            })
    }

    return (
        <div>
            <h2 style={{ margin: '1rem' }}>Advanced Search</h2>

            <Form
                layout='horizontal'
                footer={
                    <>
                        <Button block type='submit' color='primary' size='large' onClick={() => {
                            props.handleSearch(
                                {
                                    invoiceNumber,
                                    vendorId: vendor,
                                    companyId: company,
                                    startDate: startDate ? startDate.toISOString() : '',
                                    endDate: endDate ? endDate.toISOString() : ''
                                }
                            )
                        }}>
                            Search
                        </Button>
                        <Button block type='reset' color='danger' size='large'>
                            Clear
                        </Button>
                    </>
                }>
                <Form.Item
                    name='invoiceNumber'
                    label='Invoice Number'
                >
                    <Input placeholder='Invoice Number' onChange={(v) => setInvoiceNumber(v)} value={invoiceNumber} />
                </Form.Item>

                <Form.Item
                    name='vendor'
                    label='Vendor'
                >
                    <AsyncSelect cacheOptions defaultOptions loadOptions={getVendorOptions} onChange={(newValue, actionMeta) => setVendor(newValue)}/>

                </Form.Item>

                <Form.Item
                    name='company'
                    label='Company'
                >
                    <AsyncSelect cacheOptions defaultOptions loadOptions={getCompanyOptions} onChange={(newValue, actionMeta) => setCompany(newValue)}/>
                </Form.Item>

                <Form.Item
                    name='start_date'
                    label='Start Date'
                    onClick={(e, datePickerRef) => {
                        setStartDatePickerVisible(true)
                        datePickerRef.current?.open() // ⬅️
                    }}
                >
                    <DatePicker
                        title='Start Date'
                        cancelText='Cancel'
                        confirmText='Ok'
                        onConfirm={val => {
                            setStartDate(val)
                        }}
                        tillNow={true}
                        value={startDate}
                    >
                        {value => value && value.toDateString()}
                    </DatePicker>
                </Form.Item>

                <Form.Item
                    name='end_date'
                    label='End Date'
                    onClick={(e, datePickerRef) => {
                        setEndDatePickerVisible(true)
                        datePickerRef.current?.open() // ⬅️
                    }}
                >
                    <DatePicker
                        title='End Date'
                        cancelText='Cancel'
                        confirmText='Ok'
                        onConfirm={val => {
                            setEndDate(val)
                        }}
                        value={endDate}
                    >
                        {value => value && value.toDateString()}
                    </DatePicker>
                </Form.Item>
            </Form>
        </div>
    )
}
