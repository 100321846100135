import { Grid, Tag } from 'antd-mobile'
import { React } from 'react'

export function InvoiceItem ({ item }) {
    const companyAbbr = (item) => {
        if (item.CompanyId) {
            // get the first letter of each word
            const words = item.CompanyId.text.split(' ')
            return words.map(word => word[0]).join('')
        }
        return ''
    }

    const amount = (item) => {
        const locale = item.Currency === 'INR' ? 'en-IN' : 'en-US'
        return new Intl.NumberFormat(locale, { style: 'currency', currency: item.Currency }).format(item.PayableAmount || item.OriginalAmount)
    }

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString()
    }

    return (
        <Grid columns={4} gap={['0.5rem', '0.5rem']}>
            <Grid.Item span={2}>
                <div className="text-muted">
                    <b>{item.id}</b>:{companyAbbr(item)}
                    {item.WasCreatedByVendor && <span>&nbsp;🚀</span>}
                </div>
            </Grid.Item>
            <Grid.Item span={2}>
                <div className="text-right" style={{ fontSize: '80%' }}>{amount(item)}</div>
            </Grid.Item>

            <Grid.Item span={2}>
                <div className="text-muted" style={{ textAlign: 'left', textOverflow: 'ellipsis', overflow: 'hidden', textWrap: 'nowrap' }}>{item.Number}</div>
            </Grid.Item>
            <Grid.Item span={2}>
                <div className="text-muted" style={{ textAlign: 'right', textWrap: 'nowrap' }}>{formatDate(item.DoNotPayBefore)}</div>
            </Grid.Item>

            <Grid.Item span={4}>
                <div style={{ fontSize: '90%' }}>
                    <b>{item.VendorId?.Name}</b>
                    <span dangerouslySetInnerHTML={{ __html: item.VendorId?.text }}></span>
                </div>
            </Grid.Item>
        </Grid>
    )
}
