import { React, Fragment, useEffect, useState } from 'react'
import { List, Grid, Popup, Button, SwipeAction, FloatingBubble } from 'antd-mobile'
import { SpicUtils } from '../../../utils'
import DeductionItem from './DeductionItem'
import { AddOutline } from 'antd-mobile-icons'

export default function Deductions (props) {
    const [visible, setVisible] = useState(false)

    const [invoiceAdjustments, setInvoiceAdjustments] = useState(props.invoiceAdjustments)

    const [adjustments, setAdjustments] = useState([])

    const [floatingBubbleOffset, setFloatingBubbleOffset] = useState({ x: -24, y: -64 })

    useEffect(() => {
        SpicUtils.getPaymentAdjustments(props.companyId)
            .then((response) => {
                setAdjustments(response)
            })
    }, [])

    const setInvoiceAdjustmentItem = (index, clause, amount) => {
        invoiceAdjustments[index] = { clause, amount }
        setInvoiceAdjustments(invoiceAdjustments)
    }

    const addInvoiceAdjustmentItem = () => {
        const lastItem = invoiceAdjustments.length ? { ...invoiceAdjustments[invoiceAdjustments.length - 1], amount: 0 } : { clause: 'Advance Paid', amount: 0 }
        setInvoiceAdjustments([...invoiceAdjustments, lastItem])
    }
    useEffect(() => {
        props.updateInvoiceAdjustments(invoiceAdjustments)
    }, [invoiceAdjustments])

    const renderClause = (clause) => {
        if (adjustments?.length) {
            const adjustmentArray = adjustments.filter((v, i) => v.Value === clause)
            if (adjustmentArray.length > 0) {
                const adj = adjustmentArray[0]
                return `${adj.Factor > 0 ? 'Less' : 'Add'}: ${adj.Description}`
            }
        }
        return clause // Fallback
    }

    return (
        <>
            {invoiceAdjustments.map((deduction, index) => {
                return deduction.amount &&
                    deduction.amount !== 0 &&
                    <Fragment key={`deduction_${index}`}>
                        <Grid.Item span={3} style={{ paddingLeft: '1rem' }}>
                            <i>{renderClause(deduction.clause)}</i>
                        </Grid.Item>
                        <Grid.Item span={1} className="text-right">
                            {props.formatCurrency(deduction.amount)}
                        </Grid.Item>
                    </Fragment>
            }
            ).filter(n => n)}

            {props.isApprovableByMe && <Grid.Item span={4} className="text-right">
                <Button
                    onClick={() => setVisible(true)}
                    size='mini'
                    value={visible}
                >Edit</Button>
                <Popup
                    visible={visible}
                    closeOnMaskClick={true}
                    showCloseButton={true}
                    onMaskClick={() => {
                        setVisible(false)
                    }}
                    onClose={() => {
                        setVisible(false)
                    }}
                >
                    <h2 style={{ margin: '1rem 0.6rem', color: 'var(--color-text-secondary)' }}>Modify Deductions</h2>
                    <List
                        style={{ fontSize: '100%', marginBottom: '7rem', '--extra-max-width': '27%' }}
                    >
                        {invoiceAdjustments.map((deduction, index) =>
                            <SwipeAction
                                key={`sw_${index}`}
                                leftActions={[{
                                    key: 'delete-adjustment',
                                    text: 'Delete',
                                    color: 'danger',
                                    onClick: () => {
                                        invoiceAdjustments.splice(index, 1)
                                        setInvoiceAdjustments([...invoiceAdjustments])
                                    }
                                }]}
                            >
                                <DeductionItem
                                    key={`deductionitem_${index}`}
                                    index={index}
                                    deduction={deduction}
                                    currency={props.currency}
                                    adjustments={adjustments}
                                    updateAdjustmentItem={(clause, amount) => {
                                        setInvoiceAdjustmentItem(index, clause, amount)
                                    }}
                                    renderClause={renderClause}
                                />
                            </SwipeAction>
                        ).filter(n => n)}
                    </List>

                    <FloatingBubble
                        axis='xy'
                        style={{
                            '--initial-position-bottom': '0',
                            '--initial-position-right': '0',
                            '--background': 'var(--color-text-primary)'
                        }}
                        onOffsetChange={offset => {
                            setFloatingBubbleOffset(offset)
                        }}
                        offset={floatingBubbleOffset}
                        onClick={addInvoiceAdjustmentItem}
                    >
                        <AddOutline fontSize={32} />
                    </FloatingBubble>
                </Popup>
            </Grid.Item>}

        </>
    )
}
