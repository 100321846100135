import { Grid, Popup, Card, Collapse, List, Badge, Space, Tag } from 'antd-mobile'
import { React, Fragment, useEffect, useState } from 'react'
import { SpicUtils } from '../../../utils'
import { BadgeCheck, CircleX } from 'lucide-react'
import { RightOutline } from 'antd-mobile-icons'
import PurchaseOrderItemEditor from './PurchaseOrderItemEditor'
import Messages from './Messages'
import { Icons } from '../../../theme.jsx'

export default function PurchaseOrderDetails ({ invoice, updateChanges, isApprovableByMe }) {
    const [purchaseOrder, setPurchaseOrder] = useState(null)
    const [invoiceItems, setInvoiceItems] = useState(null)

    const [editPurchaseOrderItemVisible, setEditPurchaseOrderItemVisible] = useState(false)
    const [activePurchaseOrderItem, setActivePurchaseOrderItem] = useState(null)

    const locale = invoice.Currency === 'INR' ? 'en-IN' : 'en-US'
    const currencyFormat = new Intl.NumberFormat(locale, { style: 'currency', currency: invoice.Currency })
    const numberFormat = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 })

    const [invoiceSummary, setInvoiceSummary] = useState(null)
    const [messages, setMessages] = useState([])
    const [checklist, setChecklist] = useState([])

    const renderCurrencyArray = (o) => Object.entries(o).map(([key, value]) => (new Intl.NumberFormat(locale, { style: 'currency', currency: key })).format(value))

    useEffect(() => {
        const _class = SpicUtils.getInvoiceClass(invoice)
        SpicUtils.getInvoiceExtendedInformation(invoice.id, _class).then((data) => {
            setPurchaseOrder(data.result.purchase_order)
            setMessages(data.result.messages)
            setChecklist(data.result.checklist)
            setInvoiceSummary(data.result.invoice_summary || null)
        })

        SpicUtils.getInvoiceWorkflowstepItems(invoice.CurrentStep).then((data) => {
            setInvoiceItems(data.result)
        })
    }, [invoice])

    useEffect(() => {
        if (invoiceItems) {
            updateChanges(total(), invoiceItems)
        }
    }, [invoiceItems])

    const total = () => invoiceItems?.map((item) => subtotal(item)).reduce((a, b) => a + b, 0)

    const subtotal = (poItem) => {
        if (!poItem) return 0
        const base = (poItem?.quantity || 0) * (poItem?.rate || 0)
        const taxRate = Object.entries(poItem.taxes).filter(([tax, amount]) => tax === 'total')[0][1]
        const amount = base * (1 + (taxRate / 100.0))
        return amount
    }

    const onClickPurchaseOrderItem = (currentItem, item) => {
        setActivePurchaseOrderItem({ currentInvoiceItem: currentItem, currentPurchaseOrderItem: item })
        setEditPurchaseOrderItemVisible(isApprovableByMe)
    }

    const stringToColour = (str) => {
        let hash = 0
        str.split('').forEach(char => {
            hash = char.charCodeAt(0) + ((hash << 5) - hash)
        })
        let colour = '#'
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff
            colour += value.toString(16).padStart(2, '0')
        }
        return colour
    }

    const isInternalUser = () => true // isApprovableByMe

    const renderPurchaseOrderItem = (item, index) => {
        const currentItem = invoiceItems?.filter((i) => parseInt(i.purchase_order_item_id, 10) === item.id)[0]
        if (!currentItem) {
            return (
                <Fragment key='nilPurchaseOrderItem'></Fragment>
            )
        }

        return (
            <Fragment key={`frg_${item.id}`}>
                <Grid.Item span={isApprovableByMe ? 10 : 9} key={`name_${item.id}`} onClick={() => onClickPurchaseOrderItem(currentItem, item)}>
                    {index + 1}. {item.item_name}
                </Grid.Item>
                <Grid.Item span={3} className="text-right" onClick={() => onClickPurchaseOrderItem(currentItem, item)}>
                    { currentItem?.quantity ?? 0} {item.uom_name}
                </Grid.Item>
                <Grid.Item span={3} className="text-right" onClick={() => onClickPurchaseOrderItem(currentItem, item)}>
                    { new Intl.NumberFormat((currentItem?.currency === 'INR' ? 'en-IN' : 'en-US'), { style: 'currency', currency: currentItem.currency }).format(currentItem?.rate ?? 0)}
                </Grid.Item>
                <Grid.Item span={3} className="text-right" onClick={() => onClickPurchaseOrderItem(currentItem, item)}>
                    { currencyFormat.format(subtotal(currentItem))}
                </Grid.Item>
                {isApprovableByMe && <Grid.Item span={1} className="text-right" onClick={() => onClickPurchaseOrderItem(currentItem, item)}>
                    <RightOutline />
                </Grid.Item>}
                <Grid.Item span={isApprovableByMe ? 10 : 9}>
                    &nbsp;
                </Grid.Item>
            </Fragment>
        )
    }

    const renderIsItemRateMatched = (poItem, invoiceItem) => {
        // console.log(poItem, invoiceItem)
        let rateMatch = false
        if (Math.abs(parseFloat(poItem?.rate ?? 0) - parseFloat(invoiceItem?.rate ?? 0)) < 1) {
            if ((poItem?.OriginCountry ?? 'IN') !== 'IN' && (invoiceItem?.taxes?.total ?? 0) === 0) {
                rateMatch = true
            } else if (poItem?.DestinationState == null || poItem?.OriginState == null) {
                if (parseFloat(poItem?.CGST ?? '0') === parseFloat(invoiceItem?.taxes?.CGST ?? '0') &&
                    parseFloat(poItem?.SGST ?? '0') === parseFloat(invoiceItem?.taxes?.SGST ?? '0') &&
                    parseFloat(poItem?.IGST ?? '0') === parseFloat(invoiceItem?.taxes?.IGST ?? '0')) {
                    rateMatch = true
                }
            } else {
                if (poItem?.DestinationState === poItem?.OriginState) {
                    if (parseFloat(poItem?.CGST ?? '0') === parseFloat(invoiceItem?.taxes?.CGST ?? '0') &&
                        parseFloat(poItem?.SGST ?? '0') === parseFloat(invoiceItem?.taxes?.SGST ?? '0')) {
                        rateMatch = true
                    }
                } else if ((poItem?.OriginCountry ?? 'IN') === 'IN') {
                    if (parseFloat(poItem?.IGST ?? '0') === parseFloat(invoiceItem?.taxes?.IGST ?? '0')) {
                        rateMatch = true
                    }
                }
            }
        }

        if (rateMatch) {
            return Icons.success(`Rate Matched: ${poItem.rate} = ${invoiceItem.rate}`, { verticalAlign: 'middle' })
        } else {
            return <>
                {Icons.error('Rate Mismatch', { verticalAlign: 'middle' })}
                PO Rate: {(new Intl.NumberFormat(locale, { style: 'currency', currency: poItem.currency })).format(poItem.rate)}
            </>
        }
    }

    const renderItemApproved = (poItem, invoiceItem) => {
        return <>{`${numberFormat.format(poItem.approved_quantity || 0)}`}</>
    }

    const renderItemStored = (poItem, invoiceItem) => {
        if (poItem.stored_kg === poItem.stored_num && poItem.stored_kg === 0) { return 0 }

        const uomShortName = poItem.uom_unit || 'nos'
        const stored = `${numberFormat.format(poItem.stored)} ${uomShortName}`
        const issued = `${numberFormat.format(poItem.issued)} ${uomShortName}`
        let storedTitle = ''

        if (uomShortName !== 'nos') {
            storedTitle = `Stored: ${poItem.stored_kg} kg`
            if (isInternalUser()) {
                storedTitle += ` → Issued: ${poItem.issued_kg} kg`
            }

            if (uomShortName !== 'kg') {
                storedTitle += `/ ${poItem.ConversionFactor}kg/${uomShortName}`
            }
        }

        return <span
            style={ { color: poItem.conditions ? '' : 'var(--adm-color-warning)' }}
        >
            {stored + (isInternalUser() ? ` → ${issued}` : '')}
        </span>
    }

    const renderItemStoredOverInvoiced = (poItem, invoiceItem) => {
        const pct = (poItem?.invoiced_quantity ?? 0) > 0 ? Math.round((Math.max(poItem.stored_num, poItem.stored) / poItem.invoiced_quantity) * 100) : 'N/A'
        const spicStoredOverInvoicedColorStatus = pct === 'N/A' ? '--adm-color-weak' : (pct >= 100 ? '--adm-color-success' : (pct < 99 ? '--adm-color-danger' : '--adm-color-warning'))
        return <span style={ { color: `var(${spicStoredOverInvoicedColorStatus})`, fontWeight: 'bold' } }>{pct} %</span>
    }

    const renderItemInvoiced = (poItem, invoiceItem) => {
        let spicInvoicedColorStatus = '--adm-color-default'
        if (poItem.pct_invoiced_vs_ordered <= 100) {
            spicInvoicedColorStatus = '--adm-color-success'
        } else if (poItem.pct_invoiced_vs_ordered <= 105) {
            spicInvoicedColorStatus = '--adm-color-warning'
        } else if (poItem.pct_invoiced_vs_ordered > 105) {
            spicInvoicedColorStatus = '--adm-color-danger'
        }
        return <span style={ { color: `var(${spicInvoicedColorStatus})`, fontWeight: 'bold' } }>{poItem.invoiced_quantity}</span>
    }

    const renderItemDue = (poItem, invoiceItem) => {
        return <>{numberFormat.format(poItem.due)}%</>
    }

    const renderItemPaid = (poItem, invoiceItem) => {
        return <>{numberFormat.format(poItem.paid)}</>
    }

    const renderPurchaseOrderItemStock = (item, index) => {
        const currentInvoiceItem = invoiceItems?.filter((i) => parseInt(i.purchase_order_item_id, 10) === item.id)[0]
        if (!currentInvoiceItem) {
            return (
                <Fragment key='nilStock'></Fragment>
            )
        }

        return (
            <Fragment key={`frg_${item.id}`}>
                <Grid.Item span={8} key={`name_${item.id}`}>
                    <div style={ { marginTop: '1rem' } }>{item.item_name} {renderIsItemRateMatched(item, currentInvoiceItem)}</div>
                </Grid.Item>
                <Grid.Item span={1} className="text-right" style={ { fontSize: '88%' } }>
                    {renderItemApproved(item, currentInvoiceItem)}
                </Grid.Item>
                <Grid.Item span={1} className="text-right" style={ { fontSize: '88%' } }>
                    {item.transported_quantity}
                </Grid.Item>
                <Grid.Item span={2} className="text-right" style={ { fontSize: '88%', whiteSpace: 'nowrap' } }>
                    {renderItemStored(item, currentInvoiceItem)}
                </Grid.Item>
                <Grid.Item span={1} className="text-right" style={ { fontSize: '88%' } }>
                    {renderItemInvoiced(item, currentInvoiceItem)}
                </Grid.Item>
                <Grid.Item span={1} className="text-right" style={ { fontSize: '88%' } }>
                    {renderItemStoredOverInvoiced(item, currentInvoiceItem)}
                </Grid.Item>
                <Grid.Item span={1} className="text-right" style={ { fontSize: '88%' } }>
                    {renderItemDue(item, currentInvoiceItem)}
                </Grid.Item>
                <Grid.Item span={1} className="text-right" style={ { fontSize: '88%' } }>
                    {renderItemPaid(item, currentInvoiceItem)}
                </Grid.Item>
            </Fragment>
        )
    }

    const renderPurchaseOrderLine = (po) => {
        const color = (po.id === purchaseOrder.id) ? '#ffeecc' : (po.purchase_date < purchaseOrder.purchase_date ? '#ffcccc' : null)
        return (
            <div
                key={`POX-${po.id}`}
                onClick={() => {
                    window.open(SpicUtils.linkTo('POX' + purchaseOrder.id))
                }}
                style={ { margin: 0, padding: '0.5rem', backgroundColor: color } }
            >
                <Grid columns={4} gap={['0.5rem', '1.2rem']}>
                    <Grid.Item span={3}>
                        <>{`PO #${po.id}`} - {(new Date(po.purchase_date)).toLocaleDateString('en-IN')}</>
                    </Grid.Item>
                    <Grid.Item span={1} className="text-right">
                        {`[${renderCurrencyArray(po.net_value).join(', ')}]`}
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <Tag color={po.status_bg}>{po.status_text}</Tag>
                    </Grid.Item>
                    <Grid.Item span={2} className='text-right'>
                        <Tag color={stringToColour(po.location_name)}>{po.location_name}</Tag>
                    </Grid.Item>
                </Grid>
            </div>
        )
    }

    const renderInvoiceSummary = (currency, summary) =>
        <div style={{ fontSize: 'var(--adm-font-size-7)', fontWeight: 'bold' }}>
            <div>
                {`${summary.invoice_count} ${currency} ${summary.invoice_count === 1 ? 'invoice' : 'invoices'}`}: {`${(new Intl.NumberFormat(locale, { style: 'currency', currency })).format(summary.invoices)}`}
            </div>
            <div>
                <span style={{ color: checklist['po-net-value'] ? 'green' : 'red' }}>{`${summary.factor}%`} of PO value</span>
            </div>
        </div>

    return purchaseOrder && (
        <>
            <Card
                key='purchase-order-items'
                title={<h4>Purchase Order Items</h4>}
                extra={<b>{`#${purchaseOrder?.id}`}</b>}
                style={{ fontSize: 'var(--adm-font-size-6)', margin: '0.5rem', borderRadius: '0.3rem' }}
                bodyClassName="invoice-detail-card-body"
            >
                <Grid columns={isApprovableByMe ? 10 : 9} gap={['0.5rem', '1.2rem']}>
                    <Grid.Item span={3} className="text-right">
                        <strong>Qty</strong>
                    </Grid.Item>
                    <Grid.Item span={3} className="text-right">
                        <strong>Rate</strong>
                    </Grid.Item>
                    <Grid.Item span={3} className="text-right">
                        <strong>Subtotal</strong>
                    </Grid.Item>
                    {isApprovableByMe && <Grid.Item span={1} className="text-right">
                    &nbsp;
                    </Grid.Item>}
                    {purchaseOrder?.purchase_order_items?.map((item, index) => renderPurchaseOrderItem(item, index))}
                    <Grid.Item span={6}>
                        <b>TOTAL</b>
                    </Grid.Item>
                    <Grid.Item span={3} className="text-right">
                        <b>{currencyFormat.format(total())}</b>
                    </Grid.Item>
                    {isApprovableByMe && <Grid.Item span={1}></Grid.Item>}
                </Grid>
                <Popup
                    visible={editPurchaseOrderItemVisible}
                    onMaskClick={() => setEditPurchaseOrderItemVisible(false)}
                >
                    <PurchaseOrderItemEditor
                        activePurchaseOrderItem={activePurchaseOrderItem}
                        onChange={(value) => {
                            const newII = invoiceItems.map((item) => item.purchase_order_item_id === value.purchase_order_item_id ? value : item)
                            setInvoiceItems(newII)
                            setEditPurchaseOrderItemVisible(false)
                        }}
                    ></PurchaseOrderItemEditor>
                </Popup>
            </Card>
            <Card
                key='purchase-order-history'
                title={<h4>Purchase Order History</h4>}
                bodyClassName="invoice-detail-card-body"
            >
                <div
                    style={
                        {
                            borderRadius: '0.3rem',
                            padding: 0,
                            margin: 0,
                            overflow: 'hidden'
                        }
                    }
                >
                    {renderPurchaseOrderLine(purchaseOrder)}
                </div>

                <Collapse>
                    {checklist['earliest-po'] === true
                        ? <Collapse.Panel
                            key='earliest-po'
                            arrow={false}

                            title={<span>{Icons.success()}{`This is the ${purchaseOrder.other_purchase_orders.length === 0 ? 'only' : 'earliest'} PO`}</span>}
                        />
                        : <Collapse.Panel
                            key='earliest-po'
                            title={<span>{Icons.error('Older POs are still open', { verticalAlign: 'middle', marginRight: '0.5rem' }) }Older POs are still open</span>}
                        >
                            <ul className='sub-list'>
                                {purchaseOrder.other_purchase_orders.map((po, index) => <li key={index} style={ { listStyleType: 'none' } }>{renderPurchaseOrderLine(po)}</li>)}
                            </ul>
                        </Collapse.Panel>}
                </Collapse>

            </Card>
            <Card
                key='invoice-history'
                title={<h4>Invoice History</h4>}
                bodyClassName="invoice-detail-card-body"
            >
                <Collapse>
                    {invoiceSummary && Object.entries(invoiceSummary).map((entry, index) => {
                        const [currency, summary] = entry
                        return (
                            <Collapse.Panel
                                key={`invoiceSummary_${currency}`}
                                title={renderInvoiceSummary(currency, summary)}
                            >
                                <Grid columns={3}>
                                    {purchaseOrder.invoices.filter(inv => inv.Currency === currency).map((inv, index) => (
                                        <Fragment key={inv.Id}>
                                            <Grid.Item>
                                                {`${inv.Id}`}
                                            </Grid.Item>
                                            <Grid.Item>
                                                {`${inv.Number}`}
                                            </Grid.Item>
                                            <Grid.Item className='text-right'>
                                                {`${(new Intl.NumberFormat(locale, { style: 'currency', currency: inv.Currency })).format(inv.OriginalAmount)}`}
                                            </Grid.Item>
                                            <Grid.Item span={3} style={{ paddingBottom: '1rem', color: 'var(--adm-color-text)' }}>
                                                {`${inv.StatusDisplay}`}

                                            </Grid.Item>
                                        </Fragment>
                                    ))}
                                </Grid>
                            </Collapse.Panel>
                        )
                    })}

                </Collapse>
            </Card>
            <Card
                key='purchase-order-stock-flow'
                title={<h4>Purchase Order Stock Flow</h4>}
                bodyClassName="invoice-detail-card-body"
            >
                <Grid columns={8} gap={['0.5rem', '1.2rem']}>
                    <Grid.Item span={1} className="text-right">
                        <strong>O</strong>
                    </Grid.Item>
                    <Grid.Item span={1} className="text-right">
                        <strong>D</strong>
                    </Grid.Item>
                    <Grid.Item span={2} className="text-right">
                        <strong>S</strong>
                    </Grid.Item>
                    <Grid.Item span={1} className="text-right">
                        <strong>I</strong>
                    </Grid.Item>
                    <Grid.Item span={1} className="text-right">
                        <strong>S/I</strong>
                    </Grid.Item>
                    <Grid.Item span={1} className="text-right">
                        <strong>Due</strong>
                    </Grid.Item>
                    <Grid.Item span={1} className="text-right">
                        <strong>P</strong>
                    </Grid.Item>

                    {purchaseOrder?.purchase_order_items?.map((item, index) => renderPurchaseOrderItemStock(item, index))}

                </Grid>
            </Card>
            <Messages key='messages' messages={messages} />
        </>
    )
}
