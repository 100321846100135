import Cookies from 'js-cookie'
export class SpicUtils {
    static invoiceCache = new Map()
    static queryCache = new Map()
    static meCache = new Map()

    static async whoami () {
        const me = await SpicUtils.me()
        return me?.user?.Id || -1
    }

    static async me () {
        const sessid = Cookies.get('PHPSESSID') || false

        if (!sessid) {
            return { user: { Id: -1 } }
        }

        if (SpicUtils.meCache.has(sessid)) {
            return SpicUtils.meCache.get(sessid)
        }

        return await SpicUtils.fetch('/actions/me.json.php')
            .then(response => response.json())
            .then((payload) => {
                if (payload.status === 'ok') {
                    SpicUtils.meCache.set(sessid, payload.data)
                    return payload.data
                }
            })
    }

    static linkTo (docId) {
        return 'https://grapevine.hartex.in/ui/list.php?' + docId
    }

    static async fetch () {
        return await fetch(...arguments)
            .then((response) => {
                if (response.redirected) {
                    const u = new URL(response.url)

                    window.location.href = (`${u.pathname}?redirect=/app`)
                }
                return response
            })
    }

    static async getInvoice (id) {
        const endpoint = '/actions/crud.json.php'
        return await SpicUtils.fetch(`${endpoint}?_class=Invoice&id=${id}&depth=2`)
            .then(response => response.json())
            .then((data) => {
                if (data.status === 'ok') {
                    SpicUtils.invoiceCache.set(id, data.items)
                    return data.items
                }
            })
    }

    static async getInvoiceFromArchive (id) {
        const endpoint = '/actions/crud.json.php'
        return await SpicUtils.fetch(`${endpoint}?_class=InvoiceArchive&id=${id}&depth=2`)
            .then(response => response.json())
            .then((data) => {
                if (data.status === 'ok') {
                    SpicUtils.invoiceCache.set(id, data.items)
                    return data.items
                }
            })
    }

    static async getPaymentAdjustments (companyId) {
        const endpoint = '/actions/crud.json.php'
        return await SpicUtils.fetch(`${endpoint}?_class=PaymentAdjustmentType&f=filterByCompanyId,${companyId}&depth=1`)
            .then(response => response.json())
            .then((data) => {
                if (data.status === 'ok') {
                    return data.items
                }
            })
    }

    static async getCustomPayments (invoiceId) {
        const endpoint = '/actions/crud.json.php'
        return await SpicUtils.fetch(`${endpoint}?_class=InvoiceCustomPayments&f=filterByInvoiceId,${invoiceId}&depth=1`)
            .then(response => response.json())
            .then((data) => {
                if (data.status === 'ok') {
                    return data.items
                }
            })
    }

    static getInvoiceClass (invoice) {
        return (invoice.Status > 9 || invoice.Status < 0) ? 'InvoiceArchive' : 'Invoice'
    }

    static async listAttachments (id, _class) {
        return await SpicUtils.act({
            _class: _class || 'Invoice',
            _method: 'listAttachments',
            Id: id
        }).then((data) => {
            if (data.status === 'ok') {
                return Object.values(data.result)
            }
            console.error('listAttachments', data)
            return []
        })
    }

    static async uploadAttachment (invoiceId, file) {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('id', invoiceId)

        return SpicUtils.fetch('/vendors/invoices.attachments.json.php', {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: formData
        })
            .then(response => response.json())
            .catch((err) => console.error(err))
    }

    static async getInvoiceExtendedInformation (id, _class) {
        return await SpicUtils.act({
            _class: _class || 'Invoice',
            _method: 'getExtendedInformation',
            Id: id
        })
    }

    static async getInvoiceWorkflowstepItems (currentStepId) {
        return await SpicUtils.act({
            _class: 'InvoiceWorkflowstep',
            _method: 'getItems',
            Id: currentStepId
        })
    }

    static async getInvoiceWorkflowstepsForInvoice (id) {
        const endpoint = '/actions/crud.json.php'
        return await SpicUtils.fetch(`${endpoint}?_class=InvoiceWorkflowstep&f=filterByInvoiceId,${id}&depth=2`)
            .then(response => response.json())
            .then((data) => {
                if (data.status === 'ok' && data.items.length > 0) {
                    return data.items
                } else {
                    return SpicUtils.fetch(`${endpoint}?_class=InvoiceWorkflowstepArchive&f=filterByInvoiceId,${id}&depth=2`)
                        .then(response => response.json())
                        .then((data) => {
                            if (data.status === 'ok' && data.items.length > 0) {
                                return data.items
                            }
                        })
                }
            })
    }

    static async getInvoices (page, maxPerPage = 100, query = null) {
        const endpoint = '/actions/crud.json.php'
        page = parseInt(page, 10) || 1
        maxPerPage = Math.min(1000, parseInt(maxPerPage, 10)) || 100

        let url = ''

        if (!query) {
            url = `${endpoint}?_class=Invoice&page=${page}&max_per_page=${maxPerPage}&depth=0`
        } else {
            const q = encodeURIComponent(query)
            url = `${endpoint}?_class=Invoice&q=${q}&page=${page}&max_per_page=${maxPerPage}&depth=0`
        }

        // Check if it exists in the queryCache
        if (SpicUtils.queryCache.has(url)) {
            const { ids, totalCount } = SpicUtils.queryCache.get(url)

            const items = await Promise.all(ids.map(async id => {
                if (SpicUtils.invoiceCache.has(id)) {
                    return SpicUtils.invoiceCache.get(id)
                } else {
                    return await SpicUtils.getInvoice(id)
                }
            }))

            const cachedResponse = {
                items: items,
                status: 'ok',
                source: 'cache',
                max_per_page: ids.length,
                page: 1,
                total_count: totalCount
            }

            return await cachedResponse
        }

        const response = await (await SpicUtils.fetch(url)).json()

        SpicUtils.queryCache.set(url, { ids: response.items.map((invoice) => invoice.id), totalCount: response.total_count })
        response.items.forEach(element => {
            SpicUtils.invoiceCache.has(element.id) || SpicUtils.invoiceCache.set(element.id, element)
        })

        return response
    }

    static async act (body) {
        const endpoint = '/actions/crud.json.php'

        SpicUtils.invoiceCache.delete(body.Id)
        return await SpicUtils.fetch(endpoint, {
            method: 'PUT',
            headers: {
                Accept: 'application/json'
            },
            body: JSON.stringify(body)
        }).then(response => response.json())
    }

    static async submitInvoice (id) {
        return await SpicUtils.act({
            _class: 'Invoice',
            _method: 'submit',
            Id: id
        })
    }

    static async autopayInvoice (id) {
        return await SpicUtils.act({
            _class: 'Invoice',
            _method: 'autopay',
            Id: id
        })
    }

    static async holdInvoice (id) {
        return await SpicUtils.act({
            _class: 'Invoice',
            _method: 'holdLonger',
            Id: id
        })
    }

    static async recallInvoice (id) {
        return await SpicUtils.act({
            _class: 'Invoice',
            _method: 'recall',
            Id: id
        })
    }

    static async deleteInvoice (id) {
        return await SpicUtils.act({
            _class: 'Invoice',
            _method: 'deleteWithoutArchive',
            Id: id
        })
    }

    static async rejectInvoiceWorkflowstep (
        invoiceWorkflowstepId,
        approvedAmount,
        deductions,
        comments,
        dnpb,
        deductionDetails,
        grapevineDocClass,
        grapevineDocId,
        purchaseOrderItems
    ) {
        const params = {
            _class: 'InvoiceWorkflowstep',
            _method: 'reject',
            Id: invoiceWorkflowstepId,
            approved_amount: approvedAmount,
            deductions,
            comments,
            dnpb,
            deduction_details: JSON.stringify(deductionDetails),
            grapevine_doc_class: grapevineDocClass,
            grapevine_doc_id: grapevineDocId,
            purchase_order_items: JSON.stringify(purchaseOrderItems)
        }

        return await SpicUtils.act(params)
    }

    static async approveInvoiceWorkflowstep (
        invoiceWorkflowstepId,
        approvedAmount,
        deductions,
        comments,
        dnpb,
        deductionDetails,
        grapevineDocClass,
        grapevineDocId,
        purchaseOrderItems
    ) {
        const params = {
            _class: 'InvoiceWorkflowstep',
            _method: 'approve',
            Id: invoiceWorkflowstepId,
            approved_amount: approvedAmount,
            deductions,
            comments,
            dnpb,
            deduction_details: JSON.stringify(deductionDetails),
            grapevine_doc_class: grapevineDocClass,
            grapevine_doc_id: grapevineDocId,
            purchase_order_items: JSON.stringify(purchaseOrderItems)
        }

        return await SpicUtils.act(params)
    }

    static async getBankAccountsForInvoice (id) {
        return await fetch(`/admin/company.bankaccount.json.php?_method=GET&invoice_id=${id}`).then(response => response.json())
    }

    static async markPaidInvoice (id, params) {
        params.date = params.date && params.date.toISOString().split('T')[0]
        return await SpicUtils.act(Object.assign({
            _class: 'Invoice',
            _method: 'markPaid',
            Id: id
        }, params))
    }

    static async searchArchive (page, maxPerPage, query, options) {
        const endpoint = '/actions/crud.json.php'
        page = parseInt(page, 10) || 1
        maxPerPage = Math.min(1000, parseInt(maxPerPage, 10)) || 100

        // console.log(options)
        const f = encodeURIComponent(`advancedFilter,${options?.invoiceNumber || ''},${options?.vendorId?.value || ''},${options?.companyId?.value || ''},${options?.startDate || ''},${options?.endDate || ''}`)

        if (!query) {
            return await SpicUtils.fetch(
                `${endpoint}?_class=InvoiceArchive&page=${page}&max_per_page=${maxPerPage}&depth=1&f=${f}`
            ).then(response => response.json())
        }

        const q = encodeURIComponent(query)
        return await SpicUtils.fetch(
            `${endpoint}?_class=InvoiceArchive&q=${q}&page=${page}&max_per_page=${maxPerPage}&depth=1&f=${f}`
        ).then(response => response.json())
    }
    /*
    static async token() {
        if (Cookies.get('access_token')) {
            return { 'access_token': Cookies.get('access_token'), 'refresh_token': Cookies.get('refresh_token') }
        }

        let client_secret = "81b4fc922f83e40e7f89e408838437ce6799da6b87f356fd41377d37765c9794";
        let client_id = "gv-wizard";

        let details = {
            grant_type: 'client_credentials',
            client_id: client_id,
            client_secret: client_secret,
            scope: 'basic modify'
        }

        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Accept': 'application/json'
            },
            body: formBody
        }
        return fetch(`${SFAUtils.endpoint()}/oauth/token`, options)
            .then(response => response.json())
            .then((data) => {
                if (data.access_token){
                    Cookies.set('access_token', data.access_token, { expires: 1 / 48 });
                    Cookies.set('refresh_token', data.refresh_token, { expires: 1 / 48 });
                    return data;
                }
            });
    } */

    /*
    static async fetch(url, options) {
        const { access_token, refresh_token } = await SFAUtils.token();

        options = options || {};

        // Do not set 'Content-Type': 'application/json' - it will break file uploads

        let headers = {
            'Accept': 'application/json'
        };

        Object.assign(headers, options['headers'] || {});

        if (access_token) {
            headers['Authorization'] = `Bearer ${access_token}`;
        }

        if (refresh_token) {
            headers['Refresh-Token'] = refresh_token;
        }

        options['headers'] = headers;

        return fetch(`${SFAUtils.endpoint()}/api/1.1${url}`, options)
                .then((response) => {
                    if (response.headers.get('X-Grapevine-Login') === '1') {
                         // Get the current URL
                         const currentUrl = encodeURIComponent(window.location.href);
                         // Redirect to /login with the 'redirect' URL parameter
                         window.location.href = `/login?redirect=${currentUrl}`;
                    }
                    return response;
                });
    }

    static async nonce(key) {
        return SFAUtils.fetch(`/crud/nonce/?key=${key}`, {
            headers: {
                'Accept': 'application/json'
            },
        })
            .then(response => response.json())
            .then((data) => {
                return data;
            });
    }

    static href(url) {
        window.location.href = `${SFAUtils.endpoint()}${url}`;
    }

    static async getLocation(options) {

        let defaultOptions = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 60,
            maxAllowedGPSAccuracy: 150
        }

        // Override locationOptions with values from options if any are set - do not add new keys
        options = Object.assign({}, defaultOptions, options)

        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(resolve, reject, options)
            } else {
                reject("Please enable location support");
            }
        }).then(
            (location) => {
                function cloneAsObject(obj) {
                    if (obj === null || !(obj instanceof Object)) {
                        return obj;
                    }
                    var temp = (obj instanceof Array) ? [] : {};
                    // ReSharper disable once MissingHasOwnPropertyInForeach
                    for (var key in obj) {
                        temp[key] = cloneAsObject(obj[key]);
                    }
                    return temp;
                }

                if (location.coords.accuracy > options.maxAllowedGPSAccuracy) {
                    throw new Error(`GPS location is accurate to ${location.coords.accuracy} meters. Please wait until accuracy is better than ${options.maxAllowedGPSAccuracy} meters`);
                }

                localStorage.setItem('currentPosition', JSON.stringify(cloneAsObject(location)));

                return cloneAsObject(location);
            }
        )
    }

    static async uploadFile(cls, field, id, filelist) {
        // Upload a filelist to /api/1.1/crud/filer/upload/?class=cls&id=id&field=field

        return SFAUtils.nonce('edit-form')
            .then((res) => {
                // Check if filelist.length > 1 and field does not end with [] then add [] to field
                if (filelist && filelist.length > 1 && !field.endsWith('[]')) {
                    field = `${field}[]`;
                }

                const nonce = res.nonce;
                const url = `/crud/filer/upload/?class=${cls}&id=${id}&field=${field}&nonce=${nonce}`;

                const formData = new FormData();

                // Loop through each file in filelist and append to formData
                for (let i = 0; i < filelist.length; i++) {
                    formData.append(field, filelist[i], filelist[i].name);
                }

                return SFAUtils.fetch(url, {
                    method: 'POST',
                    body: formData
                });

            })
            .then((res) => (res.json()))
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.error(err)
            })

    }

    static async removeFile(cls, field, id, filename) {
        // Nonce needs to be handled
        return SFAUtils.nonce('edit-form')
            .then((res) => {
                const nonce = res.nonce

                const formData = new FormData();

                // Append data to formData
                formData.append('nonce', nonce);
                formData.append('file', filename);
                formData.append('server_data[class]', cls);
                formData.append('server_data[field]', field);
                formData.append('server_data[id]', id);

                const url = `/crud/filer/remove/`;
                // POST a request to the url payload: server_data, nonce and filename
                return SFAUtils.fetch(url, {
                    method: 'POST',
                    body: formData
                })

            }).catch((err) => {
                console.error(err)
            })

    }
    */
}
