import { TabBar } from 'antd-mobile'
import { PieOutline, BillOutline, ReceiptOutline, SearchOutline } from 'antd-mobile-icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as InvoiceLogo } from './icons/invoice.svg'
import { React, useState } from 'react'

import { Archive, BadgeIndianRupee, ReceiptIndianRupee } from 'lucide-react'

export default function Bottom () {
    const navigate = useNavigate()
    const location = useLocation()
    const [activeKey, setActiveKey] = useState(location.pathname)

    const tabs = [
        {
            key: '/',
            title: 'Home',
            icon: <PieOutline />
        },
        {
            key: '/invoices',
            title: 'Invoices',
            icon: <ReceiptIndianRupee />,
            badge: '5'
        },
        {
            key: '/payments',
            title: 'Payments',
            icon: <BadgeIndianRupee />,
            badge: '99+'
        },
        {
            key: '/archive',
            title: 'Archive',
            icon: <Archive />
        }
    ]

    return (
        <div className="bottom-nav">
            <TabBar activeKey={activeKey} safeArea={true} onChange={(value) => {
                setActiveKey(value)
                console.log(value)
                navigate(value)
            }}>
                {tabs.map(tab => (
                    <TabBar.Item
                        key={tab.key}
                        title={tab.title}
                        icon={tab.icon}
                        badge={tab.badge}
                    >
                        {tab.content}
                    </TabBar.Item>
                ))}
            </TabBar>
        </div>
    )
}
