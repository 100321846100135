import { React, Fragment, useEffect, useState } from 'react'
import { Picker, List } from 'antd-mobile'
import CurrencyInput from 'react-currency-input-field'

export default function DeductionItem ({ index, deduction, currency, adjustments, updateAdjustmentItem, renderClause }) {
    const [clause, setClause] = useState(deduction.clause)
    const [amount, setAmount] = useState(deduction.amount)

    const [adjustmentPickerVisible, setAdjustmentPickerVisible] = useState(false)

    const genOptions = () => {
        return adjustments
            ? [adjustments.map((adj, idx) => {
                return { value: adj.Value, label: adj.Description }
            })]
            : [[]]
    }

    useEffect(() => {
        // The amount has to change sign
        const adjustmentArray = adjustments.filter((v, i) => v.Value === clause)
        if (adjustmentArray.length > 0) {
            const adjustmentAmount = adjustmentArray[0].Factor * amount
            updateAdjustmentItem(clause, adjustmentAmount)
        }
    }, [clause, amount])

    console.log(clause, amount)
    return (
        <Fragment>
            <List.Item
                extra={
                    <CurrencyInput
                        id={`deductionAmount_${index}`}
                        name={`deductionAmount_${index}`}
                        value={amount}
                        decimalsLimit={2}
                        onValueChange={(value, name, values) => {
                            setAmount(value)
                        }}
                        intlConfig={{ locale: 'en-IN', currency: currency || 'INR' }}
                        style={{ '--text-align': 'right', fontWeight: 'bold', color: 'var(--color-text-primary)' }}
                        className='adm-input-element'
                    />
                }
                description={adjustments.length && adjustments.filter((v) => v.Value === clause)[0].Description}
            >
                <i onClick={() => setAdjustmentPickerVisible(true)}>
                    {clause}
                </i>

                <Picker
                    title='Select Adjustment'
                    cancelText='Cancel'
                    confirmText='Ok'
                    columns={genOptions()}
                    visible={adjustmentPickerVisible}
                    value={[clause]}
                    onCancel={() => setAdjustmentPickerVisible(false)}
                    onClose={() => setAdjustmentPickerVisible(false)}
                    onConfirm={(v) => setClause(v[0])}
                    onSelect={(val, extend) => setClause(val[0])}
                >

                </Picker>
            </List.Item>
        </Fragment>)
}
