import { React, useEffect, useState, useRef } from 'react'
import { SpicUtils } from '../utils'
import { Card, Tag, Space } from 'antd-mobile'

export default function Dashboard (props) {
    const [user, setUser] = useState(null)

    useEffect(() => {
        SpicUtils.me().then((me) => setUser(me.user))
    }, [])

    console.log(user)
    return (
        user &&
        <Card
            title={user.Username}>
            <Space>{user?.Roles?.map(role => <Tag key={role}>{role}</Tag>)}</Space>
        </Card>
    )
}
