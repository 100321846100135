import { React } from 'react'
import { Grid, Popup, Card, Collapse, List, Badge, Space } from 'antd-mobile'
import { Icons } from '../../../theme.jsx'

export default function Messages ({ messages }) {
    const generatePanelTitle = (type, text, count) => {
        return (
            <Space>
                {Icons[type]()}<span style={ { fontSize: '85%' } }>{text}</span><Badge content={count} />
            </Space>
        )
    }
    return (
        <Card
            title={<h4>Messages</h4>}
            bodyClassName="invoice-detail-card-body"
        >
            <Collapse>
                {messages.error?.length && <Collapse.Panel key="errors" title={generatePanelTitle('error', 'Errors', messages?.error?.length || 0)}>
                    <ul className='sub-list'>
                        {messages.error.map((message, index) => (
                            <li
                                key={index}
                            >
                                <span>{message}</span>
                            </li>
                        ))}
                    </ul>

                </Collapse.Panel>}

                {messages.warning?.length && <Collapse.Panel key="warnings" title={generatePanelTitle('warning', 'Warnings', messages?.warning?.length || 0)}>
                    <ul className='sub-list'>
                        {messages.warning.map((message, index) => (
                            <li
                                key={index}
                            >
                                <span>{message}</span>
                            </li>
                        ))}
                    </ul>
                </Collapse.Panel>}

                {messages.info?.length && <Collapse.Panel key="info" title={generatePanelTitle('info', 'Info', messages?.info?.length || 0)}>
                    <ul className='sub-list'>
                        {messages.info.map((message, index) => (
                            <li
                                key={index}
                            >
                                <span>{message}</span>
                            </li>
                        ))}
                    </ul>
                </Collapse.Panel>}

            </Collapse>
        </Card>
    )
}
