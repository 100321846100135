import { List } from 'antd-mobile'
import { React } from 'react'
import { SpicUtils } from '../../../utils'

export default function TransactionReference (props) {
    const formatCurrency = (amount) => props.currencyFormatter.format(amount)

    const parseAndFormatDate = (date) => {
        const d = new Date(date)
        const options = { day: '2-digit', month: 'short', year: 'numeric' }
        return d.toLocaleString('en-IN', options)
    }

    console.log(props.transactionReferences)
    return (
        <List style={{ '--font-size': 'var(--adm-font-size-4)', '--padding-left': '0', '--padding-right': '0' }}>
            {props.transactionReferences.length && props.transactionReferences?.map(
                (tref, index) => <List.Item
                    key={index}
                    extra={<div style={{ textAlign: 'right' }}>
                        <div style={{ fontSize: '80%', color: 'var(--adm-color-text)', fontWeight: 'bold' }}>{formatCurrency(tref.amount)}</div>
                        <div style={{ fontSize: '80%', color: 'var(--adm-color-text)' }}>{parseAndFormatDate(tref.date)}</div>
                        <div>&nbsp;</div>
                    </div>}
                >
                    <div><b>Type:</b> {tref.type.toLocaleUpperCase()}</div>
                    <div><b>Status:</b> {tref.status} {props.paymentError && ` - ${props.paymentError}`}</div>
                    <div><b>Reference:</b> {tref.referenceNo}</div>
                </List.Item>
            )}
        </List>
    )
}
